/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga';

class AuthList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            filtered: [],
        };
    }

    //we get the data from the system on this call
    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            var isAdmin = localStorage.getItem('adminFlag') ? localStorage.getItem('adminFlag') === 'true' : false;

            //are they an admin?
            //no
            if (isAdmin) {
                ReactGA.pageview(window.location.pathname + window.location.search);
            }
            //are they an admin?
            //yes
            else {
                this.props.history.push('/in/f/update');
                return;
            }

            // GET request using fetch with error handling
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    SECRET: localStorage.getItem('SECRET'),
                    PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                },
            };

            fetch(window.configuration.AuthsURL, requestOptions)
                .then(async (response) => {
                    //did we get an error?
                    //yes
                    if (!response.ok) {
                        //did we get an unauthorized?
                        //yes
                        if (response.status === 401) {
                            this.props.history.push('/a/logout');
                        }
                        return;
                    }
                    //did we get an error?
                    //no
                    else {
                        var auths = await response.json();

                        //create our rows
                        var authObjects = auths.map(function (auth) {
                            return {
                                id: auth.id,
                                ipAddress: auth.ipAddress,
                                enabled: auth.enabled,
                                phoneNumber: auth.phoneNumber ? auth.phoneNumber : 'not authenticated',
                                companyName: auth.companyName ? auth.companyName : '',
                                overridePhone: auth.overridePhone ? auth.overridePhone : '',
                                dateAdded:
                                    new Date(auth.dateAdded).toLocaleDateString() +
                                    ' ' +
                                    new Date(auth.dateAdded).toLocaleTimeString(),
                                dateExpires:
                                    new Date(auth.dateExpires).toLocaleDateString() +
                                    ' ' +
                                    new Date(auth.dateExpires).toLocaleTimeString(),
                                actions: (
                                    // we've added some custom button actions
                                    <div className="actions-right">
                                        <ReactTooltip />
                                        {/* spacer between buttons */ ' '}
                                        <Button
                                            disabled={!auth.enabled}
                                            data-tip="disable"
                                            onClick={() => {
                                                //confirm that they want to remove it
                                                confirmAlert({
                                                    closeOnEscape: true,
                                                    closeOnClickOutside: true,
                                                    onClickOutside: () => {},
                                                    onKeypressEscape: () => {},
                                                    willUnmount: () => {},
                                                    afterClose: () => {},
                                                    message: 'Are you sure you want to disable this auth?',
                                                    buttons: [
                                                        {
                                                            label: 'Yes',
                                                            onClick: () => {
                                                                // PATCH request using fetch with error handling
                                                                const requestOptions = {
                                                                    method: 'PATCH',
                                                                    headers: {
                                                                        'Content-Type': 'application/json',
                                                                        SECRET: localStorage.getItem('SECRET'),
                                                                        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                                                                    },
                                                                    body: JSON.stringify({
                                                                        //we set id because this is the employee record
                                                                        id: auth.id,
                                                                        enabled: false,
                                                                        siteId: window.configuration.siteId,
                                                                    }),
                                                                };

                                                                fetch(window.configuration.AuthURL, requestOptions)
                                                                    .then(async (response) => {
                                                                        // check for error response
                                                                        //did we get an error?
                                                                        //no
                                                                        if (response.ok) {
                                                                            //refresh the page
                                                                            window.location.reload();
                                                                        }
                                                                    })
                                                                    .catch((error) => {
                                                                        console.log(error);
                                                                    });
                                                            },
                                                        },
                                                        {
                                                            label: 'No',
                                                        },
                                                    ],
                                                });
                                            }}
                                            className="btn-icon btn-round"
                                            color={auth.enabled ? 'success' : 'danger'}
                                            size="sm"
                                        >
                                            <i className={'fa ' + (auth.enabled ? 'fa-eye' : 'fa-eye-slash')} />
                                        </Button>
                                    </div>
                                ),
                            };
                        });

                        //grab our response
                        this.setState({
                            data: authObjects,
                            loading: false,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        data: [],
                        error: error,
                        loading: false,
                    });
                });
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter['value'] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    };

    render() {
        //are we loading?
        //yes
        if (this.state.loading === true) {
            return (
                <>
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </>
            );
        }

        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={9}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Auths</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.state.data}
                                        filterable={true}
                                        filtered={this.state.filtered}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        defaultFilterMethod={(filter, row, column) => {
                                            const id = filter.pivotId || filter.id;
                                            if (typeof filter.value === 'object') {
                                                return row[id] !== undefined
                                                    ? filter.value.indexOf(row[id]) > -1
                                                    : true;
                                            } else {
                                                return row[id] !== undefined
                                                    ? String(row[id]).indexOf(filter.value) > -1
                                                    : true;
                                            }
                                        }}
                                        columns={[
                                            {
                                                Header: 'Added',
                                                accessor: 'dateAdded',
                                                //sort the dates
                                                sortMethod: (a, b) => {
                                                    a = new Date(a).getTime();
                                                    b = new Date(b).getTime();
                                                    return b > a ? 1 : -1;
                                                },
                                                width: 180,
                                            },
                                            {
                                                Header: 'Expires',
                                                accessor: 'dateExpires',
                                                //sort the dates
                                                sortMethod: (a, b) => {
                                                    a = new Date(a).getTime();
                                                    b = new Date(b).getTime();
                                                    return b > a ? 1 : -1;
                                                },
                                                width: 180,
                                            },
                                            {
                                                Header: 'IP Address',
                                                accessor: 'ipAddress',
                                                width: 140,
                                                filterable: true,
                                                sortable: true,
                                            },
                                            {
                                                Header: 'Phone Number',
                                                accessor: 'phoneNumber',
                                                sortable: true,
                                                filterable: true,
                                                width: 150,
                                            },
                                            {
                                                Header: 'Override Number',
                                                accessor: 'overridePhone',
                                                sortable: true,
                                                filterable: true,
                                                width: 150,
                                            },
                                            {
                                                Header: 'Company',
                                                accessor: 'company',
                                                width: 220,
                                                filterable: true,
                                                sortable: true,
                                            },
                                            {
                                                Header: '',
                                                accessor: 'actions',
                                                sortable: false,
                                                filterable: false,
                                            },
                                        ]}
                                        defaultPageSize={this.state.data.length}
                                        showPaginationBottom={false}
                                        className="-striped -highlight"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default AuthList;
