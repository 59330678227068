/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga';

class CompanyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            filtered: [],
        };
    }

    //we get the data from the system on this call
    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);
            this.loadData();
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    loadData() {
        // GET request using fetch with error handling
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
        };

        fetch(window.configuration.CompanyURL, requestOptions)
            .then(async (response) => {
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    return null;
                }
                //did we get an error?
                //no
                else {
                    var companies = await response.json();

                    //create our rows
                    var companyObjects = companies.map(function (company) {
                        return {
                            id: company.id,
                            name: company.name,
                            contactName:
                                (company.contactFirstName ? company.contactFirstName + ' ' : '') +
                                (company.contactLastName ? company.contactLastName : ''),
                            contactEmailAddress: company.contactEmailAddress,
                            contactPhoneNumber: company.contactPhoneNumber
                                ? '(' +
                                  company.contactPhoneNumber.slice(0, 3) +
                                  ') ' +
                                  company.contactPhoneNumber.slice(3, 6) +
                                  '-' +
                                  company.contactPhoneNumber.slice(6, 10)
                                : '',
                            groupCount: company.groups
                                ? parseInt(company.groups.filter((g) => g.deleted === false).length).toLocaleString()
                                : 0,
                            employeeCount: company.employees
                                ? parseInt(company.employees.filter((e) => e.deleted === false).length).toLocaleString()
                                : 0,
                            actions: (
                                // we've added some custom button actions
                                <div className="actions-right">
                                    <ReactTooltip />
                                    {/* spacer between buttons */ ' '}
                                    <Link
                                        to={'/in/l/employees?companyId=' + company.id + '&returnAfter=/in/l/companies'}
                                    >
                                        <Button
                                            data-tip="Manage Employees"
                                            className="btn-icon btn-round"
                                            color={'success'}
                                            size="sm"
                                        >
                                            <i className="fa fa-id-badge" />
                                        </Button>
                                    </Link>
                                    {/* spacer between buttons */ ' '}
                                    <Link to={'/in/l/groups?companyId=' + company.id + '&returnAfter=/in/l/companies'}>
                                        <Button
                                            data-tip="Manage Groups"
                                            className="btn-icon btn-round"
                                            color={'success'}
                                            size="sm"
                                        >
                                            <i className="fa fa-users" />
                                        </Button>
                                    </Link>
                                    {/* spacer between buttons */ ' '}
                                    <Link
                                        to={'/in/l/questions?companyId=' + company.id + '&returnAfter=/in/l/companies'}
                                    >
                                        <Button
                                            data-tip="Manage Questions"
                                            className="btn-icon btn-round"
                                            color={'success'}
                                            size="sm"
                                        >
                                            <i className="now-ui-icons files_paper" />
                                        </Button>
                                    </Link>
                                    {/* spacer between buttons */ ' '}
                                    <Link
                                        to={
                                            company.id === 'e3cf1c05-b57c-4865-9b44-85314ec65a61'
                                                ? 'update#replyall'
                                                : '/in/f/company?id=' + company.id
                                        }
                                    >
                                        <Button
                                            data-tip="edit"
                                            disabled={company.id === 'e3cf1c05-b57c-4865-9b44-85314ec65a61'}
                                            className="btn-icon btn-round"
                                            color={'success'}
                                            size="sm"
                                        >
                                            <i className="fa fa-edit" />
                                        </Button>
                                    </Link>
                                    {/* spacer between buttons */ ' '}
                                    <Button
                                        data-tip="remove"
                                        //do not let us remove the corporate one
                                        disabled={company.id === 'e3cf1c05-b57c-4865-9b44-85314ec65a61'}
                                        onClick={() => {
                                            //confirm that they want to remove it
                                            confirmAlert({
                                                closeOnEscape: true,
                                                closeOnClickOutside: true,
                                                onClickOutside: () => {},
                                                onKeypressEscape: () => {},
                                                willUnmount: () => {},
                                                afterClose: () => {},
                                                message:
                                                    'Are you sure you want to remove this company and all ' +
                                                    company.groups.length +
                                                    ' of its groups?',
                                                buttons: [
                                                    {
                                                        label: 'Yes',
                                                        onClick: () => {
                                                            // DELETE request using fetch with error handling
                                                            const requestOptions = {
                                                                method: 'DELETE',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                    SECRET: localStorage.getItem('SECRET'),
                                                                    PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                                                                },
                                                            };

                                                            fetch(
                                                                window.configuration.CompanyURL +
                                                                    '?companyId=' +
                                                                    company.id,
                                                                requestOptions,
                                                            )
                                                                .then(async (response) => {
                                                                    // check for error response
                                                                    //did we get an error?
                                                                    //no
                                                                    if (response.ok) {
                                                                        //refresh the page
                                                                        window.location.reload();
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                });
                                                        },
                                                    },
                                                    {
                                                        label: 'No',
                                                    },
                                                ],
                                            });
                                        }}
                                        className="btn-icon btn-round"
                                        color={'danger'}
                                        size="sm"
                                    >
                                        <i className="fa fa-times" />
                                    </Button>
                                </div>
                            ),
                        };
                    });

                    //grab our response
                    this.setState({
                        data: companyObjects,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    loading: false,
                });
                return null;
            });
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter['value'] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    };

    render() {
        //are we loading?
        //yes
        if (this.state.loading === true) {
            return (
                <>
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </>
            );
        }
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={10}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Companies</CardTitle>
                                    <CardTitle>
                                        <Link style={{ color: '#1aafc4' }} to={'/in/f/company'}>
                                            Add a new company
                                        </Link>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.state.data}
                                        filterable={true}
                                        filtered={this.state.filtered}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        defaultFilterMethod={(filter, row, column) => {
                                            const id = filter.pivotId || filter.id;
                                            if (typeof filter.value === 'object') {
                                                return row[id] !== undefined
                                                    ? filter.value.indexOf(row[id]) > -1
                                                    : true;
                                            } else {
                                                return row[id] !== undefined
                                                    ? String(row[id]).indexOf(filter.value) > -1
                                                    : true;
                                            }
                                        }}
                                        columns={[
                                            {
                                                Header: 'Name',
                                                accessor: 'name',
                                                width: 300,
                                            },
                                            {
                                                Header: 'Contact',
                                                accessor: 'contactName',
                                                width: 250,
                                            },
                                            {
                                                Header: 'Phone Number',
                                                accessor: 'contactPhoneNumber',
                                                width: 150,
                                            },
                                            {
                                                Header: 'Email Address',
                                                accessor: 'contactEmailAddress',
                                                width: 150,
                                            },
                                            {
                                                Header: 'Employees',
                                                accessor: 'employeeCount',
                                                width: 110,
                                                filterable: false,
                                                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
                                            },
                                            {
                                                Header: 'Groups',
                                                accessor: 'groupCount',
                                                width: 110,
                                                filterable: false,
                                                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
                                            },
                                            {
                                                Header: '',
                                                accessor: 'actions',
                                                sortable: false,
                                                filterable: false,
                                            },
                                        ]}
                                        defaultPageSize={this.state.data.length}
                                        showPaginationBottom={false}
                                        className="-striped -highlight"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default CompanyList;
