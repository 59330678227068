/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
    Form,
    FormGroup,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button,
} from 'reactstrap';
// react plugin used to create datetimepicker
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import ReactGA from 'react-ga';
import TextareaAutosize from 'react-textarea-autosize';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';

class HelpForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            redirectAfter: '',
            error: '',
            success: '',
            id: '',
            subject: '',
            subjectState: '',
            body: '',
            bodyState: '',
            loading: true,
            privateLabelObject: { id: null, brandName: 'ReplyAll Health' },
        };
    }

    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            var privateLabel = JSON.parse(localStorage.getItem('privateLabel'));

            this.setState({
                loading: false,
                privateLabelObject: privateLabel,
            });
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e.key === 'Enter') {
                this.submitForm(null, null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //this submits the update
    submitForm(e) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }
        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        //do we have an update with at least 7 characters?
        //no
        if (this.state.subject.length < 3) {
            this.setState({
                error: 'Tell us what you need help with above',
                disabled: false,
                loading: false,
            });
            return;
        }
        //do we have a group?
        //yes
        else {
            // POST request using fetch with error handling
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    SECRET: localStorage.getItem('SECRET'),
                    PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                },
                body: JSON.stringify({
                    siteId: window.configuration.siteId,
                    body: this.state.body,
                    subject: this.state.subject,
                    phoneNumber: localStorage.getItem('PHONENUMBER'),
                }),
            };

            fetch(window.configuration.HelpURL, requestOptions)
                .then(async (response) => {
                    // check for error response
                    //did we get an error?
                    //yes
                    if (!response.ok) {
                        //set the error
                        this.setState({
                            error: 'We were unable to add the help request',
                            disabled: false,
                            loading: false,
                        });
                    }
                    //did we get an error?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: '',
                            success:
                                'Thank you for your submission. A member of our team will reach out to you in the next 24 business hours (national holidays not included).',
                            disabled: false,
                            loading: false,
                            subject: '',
                            body: '',
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        error: error,
                        disabled: false,
                        loading: false,
                    });
                });
        }
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 800 }}>
                            <Form>
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Get Help From Support</CardTitle>
                                        We are here to help. Your submission will go right to our support team to
                                        address.
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label ' + this.state.textState}>
                                                    <Label>Tell us what you need help with *</Label>
                                                    <br />
                                                    <TextareaAutosize
                                                        style={{ width: '100%', padding: 10, borderColor: 'silver' }}
                                                        minRows={1}
                                                        maxRows={3}
                                                        className="react-select"
                                                        type="text"
                                                        maxLength="500"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                subject: e.target.value,
                                                                subjectState:
                                                                    e.target.value.length > 3
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.subject}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label ' + this.state.textState}>
                                                    <Label>Describe it in more detail *</Label>
                                                    <br />
                                                    <TextareaAutosize
                                                        style={{ width: '100%', padding: 10, borderColor: 'silver' }}
                                                        minRows={3}
                                                        maxRows={10}
                                                        className="react-select"
                                                        type="text"
                                                        maxLength="500"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                body: e.target.value,
                                                                bodyState:
                                                                    e.target.value.length > 3
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        autoCorrect="true"
                                                        value={this.state.body}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup
                                                    style={{
                                                        textAlign: 'end',
                                                        display: this.state.error || this.state.success ? '' : '',
                                                    }}
                                                >
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            paddingTop: 20,
                                                            paddingRight: 15,
                                                            display: this.state.error ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.error.toString()}
                                                    </Label>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'green',
                                                            paddingTop: 20,
                                                            paddingRight: 15,
                                                            display: this.state.success ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.success.toString()}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-right">
                                        <Button
                                            style={{ backgroundColor: this.state.privateLabelObject.successColor }}
                                            onClick={(e) => this.submitForm(e)}
                                        >
                                            {this.state.disabled
                                                ? 'One moment...'
                                                : this.state.id
                                                ? 'Update help request'
                                                : 'Submit Help Request'}
                                        </Button>
                                        {/* spacer between buttons */ ' '}
                                        <Button
                                            style={{
                                                backgroundColor: this.state.privateLabelObject.failureColor,
                                                marginLeft: 10,
                                            }}
                                            onClick={() => {
                                                this.props.history.push(
                                                    this.state.redirectAfter
                                                        ? this.state.redirectAfter
                                                        : '/in/l/dashboard',
                                                );
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default HelpForm;
