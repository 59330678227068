/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
//forms
import DashboardForm from 'views/Forms/DashboardForm';
import CompanyForm from 'views/Forms/CompanyForm.jsx';
import EmployeeForm from 'views/Forms/EmployeeForm.jsx';
import GroupForm from 'views/Forms/GroupForm.jsx';
import SubscriberForm from 'views/Forms/SubscriberForm.jsx';
import ProfileForm from 'views/Forms/ProfileForm';
import UpdateForm from 'views/Forms/UpdateForm.jsx';
import HelpForm from 'views/Forms/HelpForm.jsx';
import AccountForm from 'views/Forms/AccountForm.jsx';

//lists
import AuthList from 'views/Lists/AuthList.jsx';
import CompanyList from 'views/Lists/CompanyList.jsx';
import EmployeeList from 'views/Lists/EmployeeList.jsx';
import GroupList from 'views/Lists/GroupList.jsx';
import SubscriberList from 'views/Lists/SubscriberList.jsx';
import UpdateList from 'views/Lists/UpdateList.jsx';
import ResponseList from 'views/Lists/ResponseList.jsx';

//pages
import LoginPage from 'views/Pages/LoginPage';
import LogoutPage from 'views/Pages/LogoutPage';
import SignUpPage from 'views/Pages/SignUpPage';
import PartnerSignUpPage from 'views/Pages/PartnerSignUpPage';
import PartnerLoginPage from 'views/Pages/PartnerLoginPage';
import PartnerVerifyPage from 'views/Pages/PartnerVerifyPage';
import VerifyPage from 'views/Pages/VerifyPage';
import ConsumptionSignUpPage from 'views/Pages/ConsumptionSignUpPage';

let routes = [
    {
        path: '/f/dashboard',
        name: 'Dashboard',
        icon: 'fa fa-heartbeat',
        component: DashboardForm,
        layout: '/in',
        hidden: false,
        systemAdmin: false,
        groupAdmin: true,
        employee: false,
    },
    {
        path: '/f/update',
        name: 'Send an update',
        icon: 'fa fa-bullhorn',
        component: UpdateForm,
        layout: '/in',
        hidden: false,
        systemAdmin: true,
        groupAdmin: true,
        employee: true,
    },
    {
        path: '/l/updates',
        name: 'View Updates',
        icon: 'now-ui-icons files_paper',
        component: UpdateList,
        layout: '/in',
        hidden: false,
        systemAdmin: true,
        groupAdmin: true,
        employee: true,
    },
    {
        path: '/l/responses',
        name: 'See Replies',
        icon: 'fa fa-comment-dots',
        component: ResponseList,
        layout: '/in',
        hidden: false,
        systemAdmin: true,
        groupAdmin: true,
        employee: true,
    },
    {
        path: '/l/groups',
        name: 'My Groups',
        icon: 'fa fa-users',
        component: GroupList,
        layout: '/in',
        hidden: false,
        systemAdmin: true,
        groupAdmin: false,
        employee: true,
    },
    {
        path: '/f/group',
        name: 'Group',
        icon: 'fa fa-user',
        component: GroupForm,
        layout: '/in',
        hidden: true,
        systemAdmin: true,
        groupAdmin: true,
        employee: true,
    },
    {
        path: '/l/employees',
        name: 'Employees',
        icon: 'fa fa-id-badge',
        component: EmployeeList,
        layout: '/in',
        hidden: false,
        systemAdmin: true,
        groupAdmin: false,
        employee: false,
    },
    {
        path: '/f/employee',
        name: 'Employee',
        icon: 'fa fa-id-badge',
        component: EmployeeForm,
        layout: '/in',
        hidden: true,
        systemAdmin: true,
        groupAdmin: false,
        employee: false,
    },
    {
        path: '/f/group',
        name: 'Group',
        icon: 'fa fa-user',
        component: GroupForm,
        layout: '/in',
        hidden: true,
        systemAdmin: true,
        groupAdmin: true,
        employee: true,
    },
    {
        path: '/l/subscribers',
        name: 'Manage Subscribers',
        icon: 'fa fa-users',
        component: SubscriberList,
        layout: '/in',
        hidden: false,
        systemAdmin: true,
        groupAdmin: true,
        employee: true,
    },
    {
        path: '/f/subscriber',
        name: 'Subscribers',
        icon: 'fa fa-user',
        component: SubscriberForm,
        layout: '/in',
        hidden: true,
        systemAdmin: true,
        groupAdmin: true,
        employee: true,
    },
    {
        path: '/f/account',
        name: 'Your Account',
        icon: 'fa fa-user-circle',
        component: AccountForm,
        layout: '/in',
        hidden: false,
        systemAdmin: false,
        groupAdmin: true,
        employee: false,
    },
    {
        path: '/f/profile',
        name: 'My Profile',
        icon: 'now-ui-icons business_badge',
        component: ProfileForm,
        layout: '/in',
        hidden: false,
        systemAdmin: true,
        groupAdmin: false,
        employee: true,
    },
    {
        collapse: true,
        path: '#replyall',
        name: 'Admin',
        state: 'openAdmin',
        icon: 'fa fa-tools',
        hidden: false,
        systemAdmin: true,
        groupAdmin: false,
        employee: false,
        views: [
            {
                mini: 'A',
                path: '/l/auths',
                name: 'Auths',
                component: AuthList,
                layout: '/in',
                hidden: false,
                systemAdmin: true,
                groupAdmin: false,
                employee: false,
            },
            {
                mini: 'C',
                path: '/l/companies',
                name: 'Companies',
                component: CompanyList,
                layout: '/in',
                hidden: false,
                systemAdmin: true,
                groupAdmin: false,
                employee: false,
            },
            {
                path: '/f/company',
                name: 'Company',
                component: CompanyForm,
                layout: '/in',
                hidden: true,
                systemAdmin: true,
                groupAdmin: false,
                employee: false,
            },
        ],
    },
    {
        path: '/f/help',
        name: 'Get Help',
        icon: 'fa fa-question-circle',
        component: HelpForm,
        layout: '/in',
        hidden: false,
        systemAdmin: true,
        groupAdmin: true,
        employee: true,
    },
    {
        path: '/login',
        name: 'Login',
        icon: 'now-ui-icons media-1_button-power',
        component: LoginPage,
        layout: '/a',
        hidden: false,
        systemAdmin: false,
        groupAdmin: false,
        employee: false,
    },
    {
        path: '/logout',
        name: 'Logout',
        icon: 'now-ui-icons media-1_button-power',
        component: LogoutPage,
        layout: '/a',
        hidden: false,
        systemAdmin: true,
        groupAdmin: true,
        employee: true,
    },
    {
        path: '/signup',
        name: 'Sign Up',
        icon: 'now-ui-icons media-1_button-power',
        component: SignUpPage,
        layout: '/a',
        hidden: true,
        systemAdmin: false,
        groupAdmin: false,
        employee: false,
    },
    {
        path: '/consumption/signup',
        name: 'Sign Up',
        icon: 'now-ui-icons media-1_button-power',
        component: ConsumptionSignUpPage,
        layout: '/a',
        hidden: true,
        systemAdmin: false,
        groupAdmin: false,
        employee: false,
    },
    {
        path: '/partner/signup',
        name: 'Sign Up',
        icon: 'now-ui-icons media-1_button-power',
        component: PartnerSignUpPage,
        layout: '/a',
        hidden: true,
        systemAdmin: false,
        groupAdmin: false,
        employee: false,
    },
    {
        path: '/partner/login',
        name: 'Login',
        icon: 'now-ui-icons media-1_button-power',
        component: PartnerLoginPage,
        layout: '/a',
        hidden: false,
        systemAdmin: false,
        groupAdmin: false,
        employee: false,
    },
    {
        path: '/verify',
        name: 'Verify',
        icon: 'now-ui-icons media-1_button-power',
        component: VerifyPage,
        layout: '/a',
        hidden: true,
        systemAdmin: false,
        groupAdmin: false,
        employee: false,
    },
    {
        path: '/partner/verify',
        name: 'Verify',
        icon: 'now-ui-icons media-1_button-power',
        component: PartnerVerifyPage,
        layout: '/a',
        hidden: true,
        systemAdmin: false,
        groupAdmin: false,
        employee: false,
    },
];

export default routes;
