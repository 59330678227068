/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Label, Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga';
import moment from 'moment';
import Select from 'react-select';

class UpdateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            filtered: [],
            groupId: localStorage.getItem('groupId'),
            timing: [
                {
                    value: 'this month',
                    label: 'Show updates from this month',
                    startDate: moment().utc().format('MM-DD-YYYY'),
                },
                {
                    value: 'last month',
                    label: 'Show updates from last month',
                    startDate: moment().subtract(1, 'months').utc().format('MM-DD-YYYY'),
                },
                {
                    value: 'two months ago',
                    label: 'Show updates from two months ago',
                    startDate: moment().subtract(2, 'months').utc().format('MM-DD-YYYY'),
                },
                {
                    value: 'three months ago',
                    label: 'Show updates from three months ago',
                    startDate: moment().subtract(3, 'months').utc().format('MM-DD-YYYY'),
                },
                {
                    value: 'four months ago',
                    label: 'Show updates from four months ago',
                    startDate: moment().subtract(4, 'months').utc().format('MM-DD-YYYY'),
                },
                {
                    value: 'five months ago',
                    label: 'Show updates from five months ago',
                    startDate: moment().subtract(5, 'months').utc().format('MM-DD-YYYY'),
                },
                {
                    value: 'six months ago',
                    label: 'Show updates from six months ago',
                    startDate: moment().subtract(6, 'months').utc().format('MM-DD-YYYY'),
                },
            ],
            selectedTiming: {
                value: 'this month',
                label: 'Show updates from this month',
                startDate: moment().utc().format('MM-DD-YYYY'),
            },
            privateLabelObject: { id: null, brandName: 'ReplyAll Health' },
        };
    }

    //we get the data from the system on this call
    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            var privateLabel = JSON.parse(localStorage.getItem('privateLabel'));
            this.setState({
                privateLabelObject: privateLabel !== null ? privateLabel : this.state.privateLabelObject,
            });

            this.loadData(this.state.selectedTiming);
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    loadData(selectedTiming) {
        // GET request using fetch with error handling
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
        };

        fetch(
            window.configuration.AnnouncementsURL +
                '?groupId=' +
                this.state.groupId +
                '&startDate=' +
                selectedTiming.startDate,
            requestOptions,
        )
            .then(async (response) => {
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    return null;
                }
                //did we get an error?
                //no
                else {
                    var announcements = await response.json();

                    //create our rows
                    var announcementObjects = announcements.map(function (announcement) {
                        return {
                            id: announcement.id,
                            dateStart: moment(
                                new Date(announcement.dateStart).toLocaleDateString() +
                                    ' ' +
                                    new Date(announcement.dateStart).toLocaleTimeString(),
                            ).format('MM/DD/YY hh:mm a'),
                            text: announcement.text,
                            images:
                                announcement.media !== null && announcement.media.length > 0
                                    ? announcement.media.map((txt, index) => (
                                          <img
                                              src={txt}
                                              key={announcement.id + index}
                                              alt={txt}
                                              onClick={() => window.open(txt, '_blank')}
                                          />
                                      ))
                                    : '',
                            group: announcement.groupName,
                            actions: (
                                // we've added some custom button actions
                                <div className="actions-right">
                                    <ReactTooltip />
                                    <Button
                                        data-tip={
                                            announcement.innerCircle ? 'send to inner circle' : 'send to everyone'
                                        }
                                        className="btn-icon btn-round"
                                        style={{ backgroundColor: announcement.innerCircle ? '#1aafc4' : '#89f0f8' }}
                                        size="sm"
                                    >
                                        <i className="fa fa-user-circle" />
                                    </Button>
                                    {/* spacer between buttons */ ' '}
                                    <Button
                                        data-tip={announcement.queued ? 'sent' : 'not yet sent'}
                                        className="btn-icon btn-round"
                                        style={{ backgroundColor: announcement.queued ? '#1aafc4' : '#89f0f8' }}
                                        size="sm"
                                    >
                                        <i className="fa fa-paper-plane" />
                                    </Button>
                                    {/* spacer between buttons */ ' '}
                                    <Link
                                        to={
                                            !announcement.queued
                                                ? '/in/f/update?id=' + announcement.id + '&redirectAfter=/in/l/updates'
                                                : '#replyall'
                                        }
                                    >
                                        <Button
                                            data-tip="edit"
                                            className="btn-icon btn-round"
                                            style={{ backgroundColor: !announcement.queued ? '#1aafc4' : '#bd1e2c' }}
                                            size="sm"
                                            disabled={announcement.queued}
                                        >
                                            <i className="fa fa-edit" />
                                        </Button>
                                    </Link>
                                    {/* spacer between buttons */ ' '}
                                    <Button
                                        data-tip="remove"
                                        onClick={() => {
                                            //confirm that they want to remove it
                                            confirmAlert({
                                                closeOnEscape: true,
                                                closeOnClickOutside: true,
                                                onClickOutside: () => {},
                                                onKeypressEscape: () => {},
                                                willUnmount: () => {},
                                                afterClose: () => {},
                                                message: 'Are you sure you want to remove this update?',
                                                buttons: [
                                                    {
                                                        label: 'Yes',
                                                        onClick: () => {
                                                            // DELETE request using fetch with error handling
                                                            const requestOptions = {
                                                                method: 'DELETE',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                    SECRET: localStorage.getItem('SECRET'),
                                                                    PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                                                                },
                                                            };

                                                            fetch(
                                                                window.configuration.AnnouncementURL +
                                                                    '?id=' +
                                                                    announcement.id,
                                                                requestOptions,
                                                            )
                                                                .then(async (response) => {
                                                                    // check for error response
                                                                    //did we get an error?
                                                                    //no
                                                                    if (response.ok) {
                                                                        //refresh the page
                                                                        window.location.reload();
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                });
                                                        },
                                                    },
                                                    {
                                                        label: 'No',
                                                    },
                                                ],
                                            });
                                        }}
                                        className="btn-icon btn-round"
                                        style={{
                                            backgroundColor:
                                                announcement.queued && !announcement.closed ? '#dadada' : '#bd1e2c',
                                        }}
                                        size="sm"
                                        disabled={announcement.queued && !announcement.closed}
                                    >
                                        <i className="fa fa-times" />
                                    </Button>
                                </div>
                            ),
                        };
                    });

                    //grab our response
                    this.setState({
                        data: announcementObjects,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    error: error,
                    loading: false,
                });
                return null;
            });
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter['value'] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    };

    render() {
        if (this.state.loading === true) {
            return (
                <>
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </>
            );
        }

        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 1500 }}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Health Updates</CardTitle>
                                    <CardTitle>
                                        Here are the health updates that have been sent to subscribers. You can edit
                                        updates that have not been sent. &nbsp;
                                        <Link
                                            style={{ color: this.state.privateLabelObject.successColor }}
                                            to={'/in/f/update?redirectAfter=/in/l/updates'}
                                        >
                                            Send an update
                                        </Link>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row style={{ marginTop: 0, marginBottom: 20 }}>
                                        <Col xs={12} md={3}>
                                            <Select
                                                className="react-select"
                                                options={this.state.timing}
                                                onChange={(selectedTiming) => {
                                                    this.setState({
                                                        selectedTiming: selectedTiming,
                                                        loading: true,
                                                    });
                                                    this.loadData(selectedTiming);
                                                }}
                                                value={this.state.selectedTiming}
                                            />
                                        </Col>
                                    </Row>
                                    <ReactTable
                                        data={this.state.data}
                                        filterable={true}
                                        filtered={this.state.filtered}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        defaultFilterMethod={(filter, row, column) => {
                                            const id = filter.pivotId || filter.id;
                                            if (typeof filter.value === 'object') {
                                                return row[id] !== undefined
                                                    ? filter.value.indexOf(row[id]) > -1
                                                    : true;
                                            } else {
                                                return row[id] !== undefined
                                                    ? String(row[id]).indexOf(filter.value) > -1
                                                    : true;
                                            }
                                        }}
                                        columns={[
                                            {
                                                Header: 'Date to Send',
                                                accessor: 'dateStart',
                                                width: 150,
                                                //sort the dates
                                                sortMethod: (a, b) => {
                                                    a = new Date(a).getTime();
                                                    b = new Date(b).getTime();
                                                    return b > a ? 1 : -1;
                                                },
                                                filterable: false,
                                            },
                                            {
                                                Header: 'Update',
                                                accessor: 'text',
                                                style: { whiteSpace: 'unset' },
                                                sortable: false,
                                                filterable: false,
                                            },
                                            {
                                                Header: 'Images / Videos',
                                                accessor: 'images',
                                                sortable: false,
                                                filterable: false,
                                                width: 180,
                                            },
                                            {
                                                Header: '',
                                                accessor: 'actions',
                                                sortable: false,
                                                filterable: false,
                                                width: 150,
                                            },
                                        ]}
                                        defaultPageSize={this.state.data.length}
                                        showPaginationBottom={false}
                                        className="-striped -highlight"
                                    />
                                    <Label
                                        style={{
                                            fontSize: 14,
                                            marginTop: 10,
                                            paddingLeft: 5,
                                            display: this.state.data.length === 0 ? '' : 'none',
                                        }}
                                    >
                                        You have not sent any updates.
                                        <br />
                                        <br />
                                        <Button
                                            style={{ backgroundColor: this.state.privateLabelObject.successColor }}
                                            onClick={() => {
                                                this.props.history.push('/in/f/update');
                                            }}
                                        >
                                            Send your first update
                                        </Button>
                                    </Label>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default UpdateList;
