/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/now-ui-dashboard.scss?v=1.3.0';
import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';
import configuration from './config';
import ReactGA from 'react-ga';

const hist = createBrowserHistory();

//set the configuration in the window
window.configuration = configuration;

//Google Analytics
ReactGA.initialize(configuration.GoogleAnalytics);

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <Route
                //this is for logged in users
                path="/in"
                render={(props) => {
                    return <AdminLayout {...props} />;
                }}
            />
            <Route
                //this is the login page
                path="/a"
                render={(props) => {
                    return <AuthLayout {...props} />;
                }}
            />
            <Redirect
                to={
                    //are we logged in?
                    localStorage.getItem('LOGGEDIN') &&
                    localStorage.getItem('LOGGEDIN') === 'true' &&
                    localStorage.getItem('DATEEXPIRES') &&
                    new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
                        ? //yes = send them to the dashboard
                          '/in/f/dashboard'
                        : //no = send them to the login page
                          '/a/login'
                }
            />
        </Switch>
    </Router>,
    document.getElementById('root'),
);
