const config = {
    GoogleAnalytics: 'G-WSQ5YDRFNB',

    //https://www.google.com/recaptcha/admin/site/585233424
    reCAPTCHASiteKey: '6LcQ9OEiAAAAADVx-reF9fY_Kb3XgiUE5xeejOo3',
    reCAPTCHASecretKey: '6LcQ9OEiAAAAAAfTIlnl7TqN_I_w_Drv4qZ2OmQg',

    siteId: 'replyall-health',

    /* use these for production */
    AnnouncementURL: 'https://replyallhealth.azurewebsites.net/api/announcement',
    AnnouncementsURL: 'https://replyallhealth.azurewebsites.net/api/announcements',
    AuthURL: 'https://replyallhealth.azurewebsites.net/api/auth',
    AuthsURL: 'https://replyallhealth.azurewebsites.net/api/auths',
    CompanyURL: 'https://replyallhealth.azurewebsites.net/api/company',
    ConsumptionSignUpURL: 'https://replyallhealth.azurewebsites.net/api/consumptionsignup',
    CreditCardURL: 'https://replyallhealth.azurewebsites.net/api/creditcard',
    EmployeeURL: 'https://replyallhealth.azurewebsites.net/api/employee',
    FundsURL: 'https://replyallhealth.azurewebsites.net/api/funds',
    GroupURL: 'https://replyallhealth.azurewebsites.net/api/group',
    HelpURL: 'https://replyallhealth.azurewebsites.net/api/help',
    MemberURL: 'https://replyallhealth.azurewebsites.net/api/member',
    MetricURL: 'https://replyallhealth.azurewebsites.net/api/metric',
    PrivateLabelURL: 'https://replyallhealth.azurewebsites.net/api/privatelabel',
    PromoURL: 'https://replyallhealth.azurewebsites.net/api/promocode',
    SMSAuthURL: 'https://replyallhealth.azurewebsites.net/api/smsauth',
    SignUpURL: 'https://replyallhealth.azurewebsites.net/api/signup',
    TextResponseURL: 'https://replyallhealth.azurewebsites.net/api/textresponse',
    /* */

    /* use these for local testing with ngrok
    AnnouncementURL:    'http://c914655e8f74.ngrok.io/api/announcement',
    AnnouncementsURL:   'http://c914655e8f74.ngrok.io/api/announcements',
    AuthURL:            'http://c914655e8f74.ngrok.io/api/auth',
    AuthsURL:           'http://c914655e8f74.ngrok.io/api/auths',
    CompanyURL:         'http://c914655e8f74.ngrok.io/api/company',
    ConsumptionSignUpURL: 'http://c914655e8f74.ngrok.io/api/consumptionsignup',
    CreditCardURL:      'http://c914655e8f74.ngrok.io/api/creditcard',
    EmployeeURL:        'http://c914655e8f74.ngrok.io/api/employee',
    FundsURL:           'http://c914655e8f74.ngrok.io/api/funds',
    GroupURL:           'http://c914655e8f74.ngrok.io/api/group',
    HelpURL:            'http://c914655e8f74.ngrok.io/api/help',
    MemberURL:          'http://c914655e8f74.ngrok.io/api/member',
    MetricURL:          'http://c914655e8f74.ngrok.io/api/metric',
    PrivateLabelURL:    'http://c914655e8f74.ngrok.io/api/privatelabel',
    PromoURL:           'http://c914655e8f74.ngrok.io/api/promocode',
    SMSAuthURL:         'http://c914655e8f74.ngrok.io/api/smsauth'
    SignUpURL:          'http://c914655e8f74.ngrok.io/api/signup',
    TextResponseURL:    'http://c914655e8f74.ngrok.io/api/textresponse',
    */

    /* use these for local testing 
    AnnouncementURL: 'http://localhost:7071/api/announcement',
    AnnouncementsURL: 'http://localhost:7071/api/announcements',
    AuthURL: 'http://localhost:7071/api/auth',
    AuthsURL: 'http://localhost:7071/api/auths',
    CompanyURL: 'http://localhost:7071/api/company',
    ConsumptionSignUpURL: 'http://localhost:7071/api/consumptionsignup',
    CreditCardURL: 'http://localhost:7071/api/creditcard',
    EmployeeURL: 'http://localhost:7071/api/employee',
    FundsURL: 'http://localhost:7071/api/funds',
    GroupURL: 'http://localhost:7071/api/group',
    HelpURL: 'http://localhost:7071/api/help',
    MemberURL: 'http://localhost:7071/api/member',
    MetricURL: 'http://localhost:7071/api/metric',
    PrivateLabelURL: 'http://localhost:7071/api/privatelabel',
    PromoURL: 'http://localhost:7071/api/promocode',
    SMSAuthURL: 'http://localhost:7071/api/smsauth',
    SignUpURL: 'http://localhost:7071/api/signup',
    TextResponseURL: 'http://localhost:7071/api/textresponse',
     */
};

export default config;
