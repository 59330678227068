/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
    Input,
    Form,
    FormGroup,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import Switch from 'react-bootstrap-switch';
import TextareaAutosize from 'react-textarea-autosize';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class AccountForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            disabledCard: false,
            disabledAdd5: false,
            disabledAddFunds: false,
            error: '',
            errorCard: '',
            errorFunds: '',
            success: '',
            successCard: '',
            successFunds: '',
            welcome: '',
            open: true,
            planName: '',
            maxSubscribers: '',
            maxDailyUpdates: '',
            photoUpdates: '',
            planPricePerMonth: '',
            serviceExpiration: null,
            loading: true,
            memberCount: 0,
            adminPhones: this.formatPhone(localStorage.getItem('PHONENUMBER')),
            plans: [],
            selectedPlan: {},
            creditCardNumber: '',
            creditCardExpirationDate: '',
            creditCardCVC: '',
            group: {},
            addOns: [],
            discountTier0: 0.0,
            discountTier1: 0.0,
            discountTier2: 0.0,
            discountTier3: 0.0,
            balance: 0.0,
            ccLast4: '',
            ccExpirationDate: '',
            privateLabelObject: { id: null, brandName: 'ReplyAll Health' },
        };

        this.reactTags = React.createRef();
    }

    formatExpiration(value) {
        // return nothing if no value
        if (!value) {
            return value;
        }

        // only allows 0-9 inputs
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        // returns: mm
        if (cvLength < 2) {
            return currentValue;
        }
        // returns: mm/dd
        else {
            return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
        }
    }

    formatPhone(value) {
        // return nothing if no value
        if (!value) {
            return value;
        }

        // only allows 0-9 inputs
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        // returns: "x", "xx", "xxx"
        if (cvLength < 4) {
            return currentValue;
        }
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        else if (cvLength < 7) {
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        } else {
            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    }

    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            //is this a promotional account?
            var discountTier0 = parseFloat(localStorage.getItem('discountTier0'));
            discountTier0 = isNaN(discountTier0) === true ? 0.0 : discountTier0;
            var discountTier1 = parseFloat(localStorage.getItem('discountTier1'));
            discountTier1 = isNaN(discountTier1) === true ? 0.0 : discountTier1;
            var discountTier2 = parseFloat(localStorage.getItem('discountTier2'));
            discountTier2 = isNaN(discountTier2) === true ? 0.0 : discountTier2;
            var discountTier3 = parseFloat(localStorage.getItem('discountTier3'));
            discountTier3 = isNaN(discountTier3) === true ? 0.0 : discountTier3;

            var privateLabel = JSON.parse(localStorage.getItem('privateLabel'));

            this.setState({
                discountTier0: discountTier0,
                discountTier1: discountTier1,
                discountTier2: discountTier2,
                discountTier3: discountTier3,
                ccLast4: localStorage.getItem('ccLast4'),
                ccExpirationDate: localStorage.getItem('ccExpirationDate'),
                plans: [
                    {
                        value: 'Starter Pack',
                        label:
                            'Starter Pack  (Send 10 text updates to up to 10 people for $' +
                            (discountTier0 >= 9.99 ? 0.0 : parseFloat(9.99 - discountTier0).toFixed(2)) +
                            '/mo)',
                        photoUpdates: false,
                        maxSubscribers: 10,
                        maxMonthlyUpdates: 10,
                        planPricePerMonth: discountTier0 >= 9.99 ? 0.0 : parseFloat(9.99 - discountTier0).toFixed(2),
                    },
                    {
                        value: 'Inner Circle',
                        label:
                            'Inner Circle  (Send 30 text updates to up to 10 people for $' +
                            (discountTier1 >= 14.99 ? 0.0 : parseFloat(14.99 - discountTier1).toFixed(2)) +
                            '/mo)',
                        photoUpdates: false,
                        maxSubscribers: 10,
                        maxMonthlyUpdates: 30,
                        planPricePerMonth: discountTier1 >= 14.99 ? 0.0 : parseFloat(14.99 - discountTier1).toFixed(2),
                    },
                    {
                        value: 'Friends & Family',
                        label:
                            'Friends & Family  (Send 30 text updates to up to 25 people for $' +
                            (discountTier2 >= 24.99 ? 0.0 : parseFloat(24.99 - discountTier2).toFixed(2)) +
                            '/mo)',
                        photoUpdates: false,
                        maxSubscribers: 25,
                        maxMonthlyUpdates: 30,
                        planPricePerMonth: discountTier2 >= 24.99 ? 0.0 : parseFloat(24.99 - discountTier2).toFixed(2),
                    },
                    {
                        value: 'The Village',
                        label:
                            'The Village  (Send 30 text+photo updates to up to 50 people for $' +
                            (discountTier3 >= 39.99 ? 0.0 : parseFloat(39.99 - discountTier3).toFixed(2)) +
                            '/mo)',
                        photoUpdates: true,
                        maxSubscribers: 50,
                        maxMonthlyUpdates: 30,
                        planPricePerMonth: discountTier3 >= 39.99 ? 0.0 : parseFloat(39.99 - discountTier3).toFixed(2),
                    },
                ],
                privateLabelObject: privateLabel !== null ? privateLabel : this.state.privateLabelObject,
            });

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    SECRET: localStorage.getItem('SECRET'),
                    PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                },
            };

            //are they using consumption based?
            //yes
            if (localStorage.getItem('consumptionBased') === 'true') {
                fetch(window.configuration.FundsURL, requestOptions)
                    .then(async (response) => {
                        // check for error response
                        //did we get an error?
                        //yes
                        if (!response.ok) {
                            //set the error
                            this.setState({
                                error: 'We were unable to get the current balance on your account',
                                disabled: false,
                                disabledCard: false,
                                disabledAdd5: false,
                                disabledAddFunds: false,
                                loading: false,
                                success: '',
                                errorCard: '',
                                successCard: '',
                            });
                        }
                        //did we get an error?
                        //no
                        else {
                            var balanceResponse = await response.json();
                            this.setState({
                                error: '',
                                loading: false,
                                disabled: false,
                                disabledCard: false,
                                disabledAdd5: false,
                                disabledAddFunds: false,
                                balance: balanceResponse.balance,
                            });
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            error: error,
                            disabled: false,
                            disabledCard: false,
                            loading: false,
                            success: '',
                            errorCard: '',
                            successCard: '',
                        });
                    });
            }

            fetch(window.configuration.GroupURL, requestOptions)
                .then(async (response) => {
                    // check for error response
                    //did we get an error?
                    //yes
                    if (!response.ok) {
                        //set the error
                        this.setState({
                            error: 'We were unable to update your account',
                            disabled: false,
                            disabledCard: false,
                            disabledAdd5: false,
                            disabledAddFunds: false,
                            loading: false,
                            success: '',
                            errorCard: '',
                            successCard: '',
                        });
                    }
                    //did we get an error?
                    //no
                    else {
                        var groupResponse = await response.json();

                        //did we get a good one?
                        //yes
                        if (groupResponse.id) {
                            var newPlan = {};

                            //is this a Starter Pack?
                            //yes
                            if (groupResponse.planName === 'Starter Pack') {
                                newPlan = {
                                    value: 'Starter Pack',
                                    label:
                                        'Starter Pack  (Send 10 text updates to up to 10 people for $' +
                                        (discountTier0 >= 9.99 ? 0.0 : parseFloat(9.99 - discountTier0).toFixed(2)) +
                                        '/mo)',
                                    photoUpdates: false,
                                    maxSubscribers: 10,
                                    maxMonthlyUpdates: 10,
                                    planPricePerMonth:
                                        discountTier0 >= 9.99 ? 0.0 : parseFloat(9.99 - discountTier0).toFixed(2),
                                };
                            }
                            //is this a Starter Pack?
                            //no - inner circle
                            else if (groupResponse.planName === 'Inner Circle') {
                                newPlan = {
                                    value: 'Inner Circle',
                                    label:
                                        'Inner Circle  (Send 30 text updates to up to 10 people for $' +
                                        (discountTier1 >= 14.99 ? 0.0 : parseFloat(14.99 - discountTier1).toFixed(2)) +
                                        '/mo)',
                                    photoUpdates: false,
                                    maxSubscribers: 10,
                                    maxMonthlyUpdates: 30,
                                    planPricePerMonth:
                                        discountTier1 >= 14.99 ? 0.0 : parseFloat(14.99 - discountTier1).toFixed(2),
                                };
                            }
                            //is this a Starter Pack?
                            //no - F&F
                            else if (groupResponse.planName === 'Friends & Family') {
                                newPlan = {
                                    value: 'Friends & Family',
                                    label:
                                        'Friends & Family  (Send 30 text updates to up to 25 people for $' +
                                        (discountTier2 >= 24.99 ? 0.0 : parseFloat(24.99 - discountTier2).toFixed(2)) +
                                        '/mo)',
                                    photoUpdates: false,
                                    maxSubscribers: 25,
                                    maxMonthlyUpdates: 30,
                                    planPricePerMonth:
                                        discountTier2 >= 24.99 ? 0.0 : parseFloat(24.99 - discountTier2).toFixed(2),
                                };
                            }
                            //is this a Starter Pack?
                            //no - village
                            else if (groupResponse.planName === 'The Village') {
                                newPlan = {
                                    value: 'The Village',
                                    label:
                                        'The Village  (Send 30 text+photo updates to up to 50 people for $' +
                                        (discountTier3 >= 39.99 ? 0.0 : parseFloat(39.99 - discountTier3).toFixed(2)) +
                                        '/mo)',
                                    photoUpdates: true,
                                    maxSubscribers: 50,
                                    maxMonthlyUpdates: 30,
                                    planPricePerMonth:
                                        discountTier3 >= 39.99 ? 0.0 : parseFloat(39.99 - discountTier3).toFixed(2),
                                };
                            }

                            //do we have addons?
                            //yes
                            if (groupResponse.addOns != null) {
                                var addOnObjects = groupResponse.addOns.map(function (addOn) {
                                    //is this adding photos?
                                    //yes
                                    if (addOn.type === 'photos') {
                                        return (
                                            <FormGroup key={'a1' + addOn.dateAdded}>
                                                <Label style={{ minWidth: 80 }}>
                                                    {new Date(addOn.dateAdded).toLocaleDateString()}
                                                </Label>
                                                <Label>
                                                    {'Photo update feature added for $' +
                                                        addOn.price.toFixed(2) +
                                                        '/mo'}
                                                </Label>
                                            </FormGroup>
                                        );
                                    }
                                    //is this adding photos?
                                    //no
                                    else {
                                        return (
                                            <FormGroup key={'a2' + addOn.dateAdded}>
                                                <Label style={{ minWidth: 80 }}>
                                                    {new Date(addOn.dateAdded).toLocaleDateString()}
                                                </Label>
                                                <Label>
                                                    {'Added ' +
                                                        addOn.quantity +
                                                        ' more ' +
                                                        addOn.type +
                                                        ' for $' +
                                                        addOn.price.toFixed(2) +
                                                        '/mo'}
                                                </Label>
                                            </FormGroup>
                                        );
                                    }
                                });
                            }

                            this.setState({
                                error: '',
                                loading: false,
                                disabled: false,
                                disabledCard: false,
                                disabledAdd5: false,
                                disabledAddFunds: false,
                                welcome: groupResponse.welcome ? groupResponse.welcome : '',
                                adminPhones:
                                    groupResponse.admins !== null
                                        ? groupResponse.admins
                                              .map((number) => {
                                                  return this.formatPhone(number);
                                              })
                                              .join('\n')
                                        : '',
                                open: groupResponse.open,
                                planName: newPlan.planName,
                                maxSubscribers: newPlan.maxSubscribers,
                                maxDailyUpdates: newPlan.maxDailyUpdates,
                                photoUpdates: newPlan.photoUpdates,
                                planPricePerMonth: newPlan.planPricePerMonth,
                                memberCount: groupResponse.members ? groupResponse.members.length : 0,
                                serviceExpiration: groupResponse.serviceExpiration
                                    ? groupResponse.serviceExpiration
                                    : null,
                                success: '',
                                errorCard: '',
                                successCard: '',
                                selectedPlan: newPlan,
                                group: groupResponse,
                                addOns: addOnObjects,
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.setState({
                        error: error,
                        disabled: false,
                        disabledCard: false,
                        loading: false,
                        success: '',
                        errorCard: '',
                        successCard: '',
                    });
                });
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    addFunds(amount) {
        //did we disable the button?
        //yes
        if (this.state.disabledAddFunds) {
            return;
        }

        // POST request using fetch with error handling
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
        };

        this.setState({
            disabled: true,
            error: '',
            loading: true,
            success: '',
            errorCard: '',
            successCard: '',
            disabledCard: false,
            disabledAdd5: false,
            disabledAddFunds: true,
        });

        fetch(window.configuration.FundsURL + '?amount=' + amount, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: '',
                            disabled: false,
                            disabledCard: false,
                            disabledAdd5: false,
                            disabledAddFunds: false,
                            loading: false,
                            success: '',
                            errorFunds: 'We were unable to add funds to your account',
                            successFunds: '',
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    var serverResponse = await response.json();

                    //set the error
                    this.setState({
                        error: '',
                        success: '',
                        disabled: false,
                        disabledCard: false,
                        disabledAdd5: false,
                        disabledAddFunds: false,
                        loading: false,
                        errorFunds: serverResponse.error === null ? '' : serverResponse.error,
                        successFunds: serverResponse.error === null ? 'We have added funds to your account!' : '',
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: '',
                    disabled: false,
                    disabledCard: false,
                    disabledAdd5: false,
                    disabledAddFunds: false,
                    loading: false,
                    success: '',
                    errorCard: error,
                    successCard: '',
                });
            });
    }

    changeCreditCard(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabledCard) {
            return;
        }
        //do we have a credit card number?
        //no
        if (this.state.creditCardNumber.length < 15) {
            this.setState({
                error: '',
                successCard: '',
                errorCard: 'Please enter your credit card number',
            });
            return;
        }

        //do we have a credit card expiration?
        //no
        if (this.state.creditCardExpirationDate.length < 4) {
            this.setState({
                error: '',
                successCard: '',
                errorCard: 'Please enter your credit card expiration month and year (mm/yy)',
            });
            return;
        }

        //do we have a credit card cvc?
        //no
        if (this.state.creditCardCVC.length < 3) {
            this.setState({
                error: '',
                successCard: '',
                errorCard: 'Please enter your credit card CVC code',
            });
            return;
        }
        // POST request using fetch with error handling
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
            body: JSON.stringify({
                siteId: window.configuration.siteId,
                creditCardNumber: this.state.creditCardNumber,
                creditCardExpirationDate: this.state.creditCardExpirationDate,
                creditCardCVC: this.state.creditCardCVC,
            }),
        };

        this.setState({
            disabled: true,
            error: '',
            loading: true,
            success: '',
            errorCard: '',
            successCard: '',
            disabledCard: true,
            disabledAdd5: false,
            disabledAddFunds: false,
        });

        fetch(window.configuration.CreditCardURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: '',
                            disabled: false,
                            disabledCard: false,
                            disabledAdd5: false,
                            disabledAddFunds: false,
                            loading: false,
                            success: '',
                            errorCard: 'We were unable to update your card details',
                            successCard: '',
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    var serverResponse = await response.json();

                    //set the error
                    this.setState({
                        error: '',
                        success: '',
                        disabled: false,
                        disabledCard: false,
                        loading: false,
                        errorCard: serverResponse.error === null ? '' : serverResponse.error,
                        successCard:
                            serverResponse.error === null ? 'We have successfully updated your card details!' : '',
                        creditCardNumber: serverResponse.error !== null ? this.state.creditCardNumber : '',
                        creditCardExpirationDate:
                            serverResponse.error !== null ? this.state.creditCardExpirationDate : '',
                        creditCardCVC: serverResponse.error !== null ? this.state.creditCardCVC : '',
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: '',
                    disabled: false,
                    disabledCard: false,
                    disabledAdd5: false,
                    disabledAddFunds: false,
                    loading: false,
                    success: '',
                    errorCard: error,
                    successCard: '',
                });
            });
    }

    add5(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabledAdd5) {
            return;
        }
        // POST request using fetch with error handling
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
            body: JSON.stringify({
                siteId: window.configuration.siteId,
                creditCardNumber: this.state.creditCardNumber,
                creditCardExpirationDate: this.state.creditCardExpirationDate,
                creditCardCVC: this.state.creditCardCVC,
            }),
        };

        this.setState({
            disabled: true,
            error: '',
            loading: true,
            success: '',
            errorCard: '',
            successCard: '',
            disabledCard: true,
        });

        fetch(window.configuration.CreditCardURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: '',
                            disabled: false,
                            disabledCard: false,
                            disabledAdd5: false,
                            disabledAddFunds: false,
                            loading: false,
                            success: '',
                            errorCard: 'We were unable to update your card details',
                            successCard: '',
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    var serverResponse = await response.json();

                    //set the error
                    this.setState({
                        error: '',
                        success: '',
                        disabled: false,
                        disabledCard: false,
                        disabledAdd5: false,
                        disabledAddFunds: false,
                        loading: false,
                        errorCard: serverResponse.error === null ? '' : serverResponse.error,
                        successCard:
                            serverResponse.error === null ? 'We have successfully updated your card details!' : '',
                        creditCardNumber: serverResponse.error !== null ? this.state.creditCardNumber : '',
                        creditCardExpirationDate:
                            serverResponse.error !== null ? this.state.creditCardExpirationDate : '',
                        creditCardCVC: serverResponse.error !== null ? this.state.creditCardCVC : '',
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: '',
                    disabled: false,
                    disabledCard: false,
                    disabledAdd5: false,
                    disabledAddFunds: false,
                    loading: false,
                    success: '',
                    errorCard: error,
                    successCard: '',
                });
            });
    }

    //this submits the form
    submit(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a welcome?
        //no
        if (this.state.welcome.length <= 10) {
            this.setState({
                error: 'Please enter the welcome message for the group',
                success: '',
                errorCard: '',
                successCard: '',
            });
            return;
        }

        //do we have a plan that we cannot use?
        //yes
        if (this.state.selectedPlan.maxSubscribers < this.state.memberCount) {
            this.setState({
                error:
                    'You are unable to change to this plan because it is limited to ' +
                    this.state.selectedPlan.maxSubscribers +
                    ' subscribers and you have ' +
                    this.state.memberCount +
                    '.',
                success: '',
                errorCard: '',
                successCard: '',
            });
            return;
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
            success: '',
            errorCard: '',
            successCard: '',
            disabledCard: true,
            disabledAdd5: false,
            disabledAddFunds: false,
        });

        // POST request using fetch with error handling
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
            body: JSON.stringify({
                siteId: window.configuration.siteId,
                welcome: this.state.welcome,
                open: this.state.open,
                admins: this.state.adminPhones.split('\n'),
                planName: this.state.selectedPlan.value,
                maxSubscribers: this.state.selectedPlan.maxSubscribers,
                maxDailyUpdates: this.state.selectedPlan.maxDailyUpdates,
                photoUpdates: this.state.selectedPlan.photoUpdates,
                planPricePerMonth: this.state.selectedPlan.planPricePerMonth,
            }),
        };

        fetch(window.configuration.GroupURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: 'We were unable to update your account',
                            disabled: false,
                            disabledCard: false,
                            loading: false,
                            success: '',
                            errorCard: '',
                            successCard: '',
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    //set the error
                    this.setState({
                        error: '',
                        success: 'We have successfully updated your account!',
                        disabled: false,
                        disabledCard: false,
                        disabledAdd5: false,
                        disabledAddFunds: false,
                        loading: false,
                        errorCard: '',
                        successCard: '',
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    disabled: false,
                    disabledCard: false,
                    disabledAdd5: false,
                    disabledAddFunds: false,
                    loading: false,
                    success: '',
                    errorCard: '',
                    successCard: '',
                });
            });
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 900 }}>
                            <Form>
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Manage Your Account</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} md={12} style={{ marginTop: -10 }}>
                                                <FormGroup className={'has-label'}>
                                                    <Label>Welcome message to send when someone subscribes *</Label>
                                                    <TextareaAutosize
                                                        style={{ width: '100%', padding: 10, borderColor: 'silver' }}
                                                        minRows={2}
                                                        maxRows={3}
                                                        className="react-select"
                                                        type="text"
                                                        maxLength="320"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                welcome: e.target.value,
                                                                welcomeState:
                                                                    e.target.value.length > 4
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        autoCorrect="true"
                                                        value={this.state.welcome}
                                                    />
                                                </FormGroup>
                                                <FormGroup style={{ marginTop: -10, textAlign: 'end' }}>
                                                    <Label style={{ fontSize: 10, paddingRight: 5 }}>
                                                        {this.state.welcome.length} characters of 320
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ marginTop: -10 }}>
                                                <FormGroup className={'has-label'}>
                                                    <Label>
                                                        Admin phone numbers for people who can send updates. You cannot
                                                        remove yourself. (one per line)
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        style={{
                                                            overflow: 'hidden',
                                                            minHeight: 50,
                                                            border: '1px solid silver',
                                                            maxHeight: 500,
                                                            maxWidth: 150,
                                                            height:
                                                                25 *
                                                                    (this.state.adminPhones.match(/\n/g) || []).length +
                                                                40,
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                adminPhones: e.target.value
                                                                    .split('\n')
                                                                    .map((number) => {
                                                                        return this.formatPhone(number);
                                                                    })
                                                                    .join('\n'),
                                                                error: '',
                                                            });
                                                        }}
                                                        value={this.state.adminPhones}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ marginTop: 10 }}>
                                                <Label>Allow people to subscribe by sending 'Hi' to your number?</Label>
                                                <FormGroup className={'has-label'}>
                                                    <Switch
                                                        onColor="blue"
                                                        offColor="red"
                                                        labelWidth="auto"
                                                        handleWidth="auto"
                                                        onText="Yes"
                                                        offText="No"
                                                        defaultValue={this.state.open}
                                                        value={this.state.open}
                                                        onChange={(e) => {
                                                            this.setState({ open: e.state.value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: 10,
                                                display:
                                                    this.state.privateLabelObject.id === null &&
                                                    localStorage.getItem('consumptionBased') === 'false'
                                                        ? ''
                                                        : 'none',
                                            }}
                                        >
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label '}>
                                                    <Label>
                                                        Select the plan that best fits{' '}
                                                        {this.state.gift === true ? 'their' : 'your'} needs (you have{' '}
                                                        {this.state.memberCount === 1
                                                            ? '1 subscriber'
                                                            : this.state.memberCount + ' subscribers'}
                                                        ) *
                                                    </Label>
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        className="react-select"
                                                        options={this.state.plans}
                                                        onChange={(selectedOption) => {
                                                            this.setState({
                                                                selectedPlan: selectedOption,
                                                            });
                                                        }}
                                                        value={this.state.selectedPlan}
                                                        placeholder={'Select your plan'}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            className="text-left"
                                            style={{
                                                display:
                                                    this.state.privateLabelObject.id !== null ||
                                                    localStorage.getItem('consumptionBased') === 'true'
                                                        ? 'none'
                                                        : this.state.serviceExpiration === null
                                                        ? 'none'
                                                        : '',
                                            }}
                                        >
                                            <Col xs={12} md={12}>
                                                {this.state.addOns}
                                            </Col>
                                        </Row>
                                        <Row
                                            className="text-left"
                                            style={{
                                                display:
                                                    this.state.privateLabelObject.id !== null ||
                                                    localStorage.getItem('consumptionBased') === 'true'
                                                        ? 'none'
                                                        : this.state.serviceExpiration === null
                                                        ? 'none'
                                                        : '',
                                            }}
                                        >
                                            <Col xs={12} md={12}>
                                                <FormGroup>
                                                    <Label style={{ minWidth: 80 }}>
                                                        {new Date(this.state.serviceExpiration).toLocaleDateString()}
                                                    </Label>
                                                    <Label>
                                                        Next billing date for {this.state.group.maxSubscribers}{' '}
                                                        subscriber slots{' '}
                                                        {this.state.group.planName !== 'The Village' &&
                                                        this.state.group.photoUpdates === true
                                                            ? ' and photo updates'
                                                            : ''}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            className="text-left"
                                            style={{
                                                display:
                                                    this.state.privateLabelObject.id !== null ||
                                                    localStorage.getItem('consumptionBased') === 'true'
                                                        ? 'none'
                                                        : parseFloat(this.state.discountTier0) === 0 &&
                                                          parseFloat(this.state.discountTier1) === 0 &&
                                                          parseFloat(this.state.discountTier2) === 0 &&
                                                          parseFloat(this.state.discountTier3) === 0
                                                        ? 'none'
                                                        : '',
                                            }}
                                        >
                                            <Col xs={12} md={12}>
                                                <FormGroup>
                                                    <Label style={{ minWidth: 80 }}>
                                                        $
                                                        {this.state.selectedPlan.planName === 'Starter Pack'
                                                            ? parseFloat(this.state.discountTier0).toFixed(2)
                                                            : this.state.selectedPlan.planName === 'Inner Circle'
                                                            ? parseFloat(this.state.discountTier1).toFixed(2)
                                                            : this.state.selectedPlan.planName === 'Friends & Family'
                                                            ? parseFloat(this.state.discountTier2).toFixed(2)
                                                            : parseFloat(this.state.discountTier3).toFixed(2)}
                                                    </Label>
                                                    <Label>Maximum monthly promotional discount</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.error ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup style={{ textAlign: 'end' }}>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            paddingRight: 15,
                                                            display: this.state.error ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.error}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.success ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup style={{ textAlign: 'end' }}>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'green',
                                                            paddingRight: 15,
                                                            display: this.state.success ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.success}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={8}
                                                style={{
                                                    display:
                                                        localStorage.getItem('consumptionBased') === 'true'
                                                            ? 'none'
                                                            : '',
                                                }}
                                            >
                                                <FormGroup>
                                                    <Button
                                                        data-tip="add 5 more subscribers"
                                                        onClick={() => {
                                                            //confirm that they want to remove it
                                                            confirmAlert({
                                                                closeOnEscape: true,
                                                                closeOnClickOutside: true,
                                                                onClickOutside: () => {},
                                                                onKeypressEscape: () => {},
                                                                willUnmount: () => {},
                                                                afterClose: () => {},
                                                                message:
                                                                    'Add 5 more subscribers slots to your account?',
                                                                buttons: [
                                                                    {
                                                                        label: 'Add on for $2.99/mo',
                                                                        onClick: () => {
                                                                            // DELETE request using fetch with error handling
                                                                            const requestOptions = {
                                                                                method: 'POST',
                                                                                headers: {
                                                                                    'Content-Type': 'application/json',
                                                                                    SECRET: localStorage.getItem(
                                                                                        'SECRET',
                                                                                    ),
                                                                                    PASSPHRASE: localStorage.getItem(
                                                                                        'PASSPHRASE',
                                                                                    ),
                                                                                },
                                                                            };

                                                                            fetch(
                                                                                window.configuration.GroupURL +
                                                                                    '?add5=true',
                                                                                requestOptions,
                                                                            )
                                                                                .then(async (response) => {
                                                                                    //refresh the page
                                                                                    window.location.reload();
                                                                                })
                                                                                .catch((error) => {
                                                                                    console.log(error);
                                                                                    //refresh the page
                                                                                    window.location.reload();
                                                                                });
                                                                        },
                                                                    },
                                                                    {
                                                                        label: 'Not right now',
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                        style={{
                                                            backgroundColor: this.state.privateLabelObject.successColor,
                                                            display:
                                                                this.state.privateLabelObject.addSubscribers === true
                                                                    ? ''
                                                                    : 'none',
                                                        }}
                                                        size="md"
                                                    >
                                                        Add 5 subscriber slots
                                                    </Button>
                                                    {/* spacer between buttons */ ' '}
                                                    <Button
                                                        data-tip="add photo updates"
                                                        onClick={() => {
                                                            //confirm that they want to remove it
                                                            confirmAlert({
                                                                closeOnEscape: true,
                                                                closeOnClickOutside: true,
                                                                onClickOutside: () => {},
                                                                onKeypressEscape: () => {},
                                                                willUnmount: () => {},
                                                                afterClose: () => {},
                                                                message: 'Add photo update feature to your account?',
                                                                buttons: [
                                                                    {
                                                                        label: 'Add on for $15/mo',
                                                                        onClick: () => {
                                                                            // DELETE request using fetch with error handling
                                                                            const requestOptions = {
                                                                                method: 'POST',
                                                                                headers: {
                                                                                    'Content-Type': 'application/json',
                                                                                    SECRET: localStorage.getItem(
                                                                                        'SECRET',
                                                                                    ),
                                                                                    PASSPHRASE: localStorage.getItem(
                                                                                        'PASSPHRASE',
                                                                                    ),
                                                                                },
                                                                            };

                                                                            fetch(
                                                                                window.configuration.GroupURL +
                                                                                    '?addPhotos=true',
                                                                                requestOptions,
                                                                            )
                                                                                .then(async (response) => {
                                                                                    //refresh the page
                                                                                    window.location.reload();
                                                                                })
                                                                                .catch((error) => {
                                                                                    console.log(error);
                                                                                    //refresh the page
                                                                                    window.location.reload();
                                                                                });
                                                                        },
                                                                    },
                                                                    {
                                                                        label: 'Not right now',
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                        style={{
                                                            backgroundColor: this.state.privateLabelObject.successColor,
                                                            display:
                                                                this.state.privateLabelObject.addPhotos === true &&
                                                                this.state.group.photoUpdates === false
                                                                    ? ''
                                                                    : 'none',
                                                        }}
                                                        size="md"
                                                    >
                                                        Add photo updates
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ textAlign: 'end' }}>
                                                <FormGroup>
                                                    <hr />
                                                    <Button
                                                        style={{
                                                            backgroundColor: this.state.privateLabelObject.successColor,
                                                        }}
                                                        onClick={(e) => this.submit(e)}
                                                    >
                                                        {this.state.disabled
                                                            ? 'One moment...'
                                                            : 'Update Account Details'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardHeader
                                        style={{
                                            marginTop: 10,
                                            display: this.state.privateLabelObject.id === null ? '' : 'none',
                                        }}
                                    >
                                        <CardTitle tag="h4">Add funds to your account</CardTitle>
                                        Your current account balance is{' '}
                                        <b>${parseFloat(this.state.balance).toFixed(2)}</b>. Click a button below to add
                                        funds to your account.
                                    </CardHeader>
                                    <CardBody
                                        style={{
                                            display: this.state.privateLabelObject.id === null ? '' : 'none',
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12} md={2}>
                                                <FormGroup className={'has-label '}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: this.state.privateLabelObject.successColor,
                                                        }}
                                                        onClick={() => this.addFunds(5)}
                                                    >
                                                        {this.state.disabledAddFunds ? 'One moment...' : 'Add $5'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <FormGroup className={'has-label '}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: this.state.privateLabelObject.successColor,
                                                        }}
                                                        onClick={() => this.addFunds(10)}
                                                    >
                                                        {this.state.disabledAddFunds ? 'One moment...' : 'Add $10'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <FormGroup className={'has-label '}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: this.state.privateLabelObject.successColor,
                                                        }}
                                                        onClick={() => this.addFunds(20)}
                                                    >
                                                        {this.state.disabledAddFunds ? 'One moment...' : 'Add $20'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <FormGroup className={'has-label '}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: this.state.privateLabelObject.successColor,
                                                        }}
                                                        onClick={() => this.addFunds(50)}
                                                    >
                                                        {this.state.disabledAddFunds ? 'One moment...' : 'Add $50'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <FormGroup className={'has-label '}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: this.state.privateLabelObject.successColor,
                                                        }}
                                                        onClick={() => this.addFunds(100)}
                                                    >
                                                        {this.state.disabledAddFunds ? 'One moment...' : 'Add $100'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.errorFunds ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup style={{ textAlign: 'end' }}>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            paddingRight: 15,
                                                            display: this.state.errorFunds ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.errorFunds}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.successFunds ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup style={{ textAlign: 'start' }}>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'green',
                                                            paddingRight: 15,
                                                            display: this.state.successFunds ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.successFunds}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardHeader
                                        style={{
                                            marginTop: 10,
                                            display: this.state.privateLabelObject.id === null ? '' : 'none',
                                        }}
                                    >
                                        <CardTitle tag="h4">Update payment details</CardTitle>
                                        Enter your new credit card here for future charges. Credit card details are
                                        secured by{' '}
                                        <a
                                            href="https://stripe.com/docs/security/stripe"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Stripe
                                        </a>
                                        .{' '}
                                    </CardHeader>
                                    <CardBody
                                        style={{
                                            marginTop: 0,
                                            display: this.state.privateLabelObject.id === null ? '' : 'none',
                                        }}
                                    >
                                        <Row style={{ paddingTop: 0 }}>
                                            <Col xs={12} md={12} style={{ display: this.state.ccLast4 ? '' : 'none' }}>
                                                <FormGroup className={'has-label '}>
                                                    <Label>
                                                        {this.state.ccLast4 !== ''
                                                            ? 'The current credit card on file ends in ' +
                                                              this.state.ccLast4 +
                                                              ' and expires on ' +
                                                              this.state.ccExpirationDate +
                                                              '.'
                                                            : ''}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <FormGroup className={'has-label '}>
                                                    <Label>Credit Card Number</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="16"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                creditCardNumber: e.target.value.replace(/[^\d]/g, ''),
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.creditCardNumber}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <FormGroup className={'has-label '}>
                                                    <Label>Expiration (mm/yy)</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="5"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                creditCardExpirationDate: this.formatExpiration(
                                                                    e.target.value,
                                                                ),
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.creditCardExpirationDate}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <FormGroup className={'has-label '}>
                                                    <Label>CVC</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="4"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                creditCardCVC: e.target.value.replace(/[^\d]/g, ''),
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.creditCardCVC}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.errorCard ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            paddingRight: 15,
                                                            display: this.state.errorCard ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.errorCard}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.successCard ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'green',
                                                            paddingRight: 15,
                                                            display: this.state.successCard ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.successCard}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter
                                        className="text-right"
                                        style={{
                                            marginTop: 0,
                                            display: this.state.privateLabelObject.id === null ? '' : 'none',
                                        }}
                                    >
                                        <Button
                                            style={{ backgroundColor: this.state.privateLabelObject.successColor }}
                                            onClick={(e) => this.changeCreditCard(e)}
                                        >
                                            {this.state.disabledCard
                                                ? 'One moment...'
                                                : 'Update Credit Card Information'}
                                        </Button>
                                    </CardFooter>
                                    <CardHeader>
                                        <CardTitle tag="h4">Danger Zone</CardTitle>
                                        Ready to close your account? We are sorry to see you go and hope you have
                                        successfully navigated your health issue. Once you close your account you will
                                        not be able to use your {this.state.privateLabelObject.brandName} number.
                                    </CardHeader>
                                    <CardFooter className="text-left" style={{ marginBottom: 20 }}>
                                        <Button
                                            data-tip="close account"
                                            onClick={() => {
                                                //confirm that they want to remove it
                                                confirmAlert({
                                                    closeOnEscape: true,
                                                    closeOnClickOutside: true,
                                                    onClickOutside: () => {},
                                                    onKeypressEscape: () => {},
                                                    willUnmount: () => {},
                                                    afterClose: () => {},
                                                    message:
                                                        'Are you sure you want to close your ' +
                                                        this.state.privateLabelObject.brandName +
                                                        ' account and give up your number? WARNING: This cannot be undone.',
                                                    buttons: [
                                                        {
                                                            label: 'I am sure',
                                                            onClick: () => {
                                                                this.setState({ disabled: true, loading: true });

                                                                // DELETE request using fetch with error handling
                                                                const requestOptions = {
                                                                    method: 'DELETE',
                                                                    headers: {
                                                                        'Content-Type': 'application/json',
                                                                        SECRET: localStorage.getItem('SECRET'),
                                                                        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                                                                    },
                                                                };

                                                                fetch(
                                                                    window.configuration.CompanyURL + '?close=true',
                                                                    requestOptions,
                                                                )
                                                                    .then(async (response) => {
                                                                        //clear the global settings
                                                                        localStorage.clear();

                                                                        //send them to the other site
                                                                        this.props.history.push(
                                                                            'https://replyallhealth.com',
                                                                        );
                                                                    })
                                                                    .catch((error) => {
                                                                        console.log(error);
                                                                        //refresh the page
                                                                        window.location.reload();
                                                                    });
                                                            },
                                                        },
                                                        {
                                                            label: 'Not right now',
                                                        },
                                                    ],
                                                });
                                            }}
                                            style={{ backgroundColor: this.state.privateLabelObject.failureColor }}
                                            size="md"
                                        >
                                            Close My {this.state.privateLabelObject.brandName} Account
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default AccountForm;
