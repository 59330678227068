/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// reactstrap components
import React from 'react';

import {
    Input,
    Form,
    FormGroup,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import TextareaAutosize from 'react-textarea-autosize';
import ReactGA from 'react-ga';

const queryString = require('query-string');

class PartnerSignUpPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactFirstName: '',
            contactFirstNameState: '',
            contactLastName: '',
            contactLastNameState: '',
            contactPhoneNumber: '',
            contactPhoneNumberState: '',
            contactEmailAddress: '',
            contactEmailAddressState: '',
            gift: false,
            purchaserFirstName: '',
            purchaserFirstNameState: '',
            purchaserLastName: '',
            purchaserLastNameState: '',
            purchaserPhoneNumber: '',
            purchaserPhoneNumberState: '',
            purchaserEmailAddress: '',
            purchaserEmailAddressState: '',
            promoCode: '',
            promoObject: null,
            privateLabelObject: { id: null, brandName: '' },
            disabled: false,
            error: '',
            welcome:
                'Thank you subscribing to updates about my health journey. I will keep you up to date from this number.',
            loading: true,
            adminPhone1: '',
            adminPhone2: '',
            adminPhone3: '',
        };

        this.reactTags = React.createRef();
    }

    formatPhone(value) {
        // return nothing if no value
        if (!value) {
            return value;
        }

        // only allows 0-9 inputs
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        // returns: "x", "xx", "xxx"
        if (cvLength < 4) {
            return currentValue;
        }
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        else if (cvLength < 7) {
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        } else {
            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    }

    formatExpiration(value) {
        // return nothing if no value
        if (!value) {
            return value;
        }

        // only allows 0-9 inputs
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        // returns: mm
        if (cvLength < 2) {
            return currentValue;
        }
        // returns: mm/dd
        else {
            return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
        }
    }
    compon;

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);

        var qs = queryString.parse(this.props.location.search);

        // POST request using fetch with error handling
        const metricOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                siteId: window.configuration.siteId,
                page: 'signup',
                action: 'loaded',
                queryString: this.props.location.search,
            }),
        };

        //do we have a private label coming in?
        //yes
        if (qs['p']) {
            // GET request using fetch with error handling
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            fetch(window.configuration.PrivateLabelURL + '?urlCode=' + qs['p'].toUpperCase(), requestOptions)
                .then(async (response) => {
                    // check for error response
                    //did we get an error?
                    //no
                    if (response.ok) {
                        var serverResponse = await response.json();

                        //did it work?
                        //yes
                        if (serverResponse.id !== null) {
                            this.setState({
                                error: '',
                                privateLabelObject: serverResponse,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        //do we have a private label coming in?
        //no
        else {
            this.props.history.push('/a/signup');
            return;
        }

        fetch(window.configuration.MetricURL, metricOptions);

        this.setState({
            loading: false,
        });
    }

    //this submits the form
    submitPromo(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a first name?
        //no
        if (this.state.promoCode.length === 0) {
            this.setState({
                error: 'Please enter a valid promo code',
            });
            return;
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        // GET request using fetch with error handling
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        fetch(window.configuration.PromoURL + '?promoCode=' + this.state.promoCode.toUpperCase(), requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //set the error
                    this.setState({
                        error: 'We were unable to locate this promo code. Try again.',
                        promoObject: null,
                        disabled: false,
                        loading: false,
                    });
                }
                //did we get an error?
                //no
                else {
                    var serverResponse = await response.json();

                    // POST request using fetch with error handling
                    const metricOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            siteId: window.configuration.siteId,
                            page: 'partner signup',
                            action: 'checkpromocode',
                            queryString:
                                this.state.contactFirstName +
                                ' ' +
                                this.state.contactLastName +
                                ' ' +
                                this.state.promoCode.toUpperCase(),
                        }),
                    };

                    fetch(window.configuration.MetricURL, metricOptions);
                    //did it work?
                    //yes
                    if (serverResponse.id !== null) {
                        this.setState({
                            error: '',
                            promoObject: serverResponse,
                            loading: false,
                            disabled: false,
                        });
                    }
                    //did it work?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: 'We were unable to locate this promo code. Try again.',
                            promoObject: null,
                            disabled: false,
                            loading: false,
                        });
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    promoObject: null,
                    disabled: false,
                    loading: false,
                });
            });
    }

    //this submits the form
    submit(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a first name?
        //no
        if (this.state.contactFirstName.length === 0) {
            this.setState({
                error: 'Please enter ' + (this.state.gift === true ? 'their' : 'your') + ' first name',
            });
            return;
        }

        //do we have a last name?
        //no
        if (this.state.contactLastName.length === 0) {
            this.setState({
                error: 'Please enter ' + (this.state.gift === true ? 'their' : 'your') + ' last name',
            });
            return;
        }

        //do we have a phone number?
        //no
        if (this.state.contactPhoneNumber.length < 10) {
            this.setState({
                error: 'Please enter ' + (this.state.gift === true ? 'their' : 'your') + ' cell phone number',
            });
            return;
        }

        //do we have an email address?
        //no
        if (this.state.contactEmailAddress.length < 6) {
            this.setState({
                error: 'Please enter ' + (this.state.gift === true ? 'their' : 'your') + ' email address',
            });
            return;
        }

        //do we have a welcome message?
        //no
        if (this.state.welcome.length <= 10) {
            this.setState({
                error:
                    'Please enter ' + (this.state.gift === true ? 'their' : 'your') + ' welcome message to subscribers',
            });
            return;
        }

        //is this a gift?
        //yes
        if (this.state.gift === true) {
            //do we have a first name?
            //no
            if (this.state.purchaserFirstName.length === 0) {
                this.setState({
                    error: 'Please enter your first name',
                });
                return;
            }

            //do we have a last name?
            //no
            if (this.state.purchaserLastName.length === 0) {
                this.setState({
                    error: 'Please enter your last name',
                });
                return;
            }

            //do we have a phone number?
            //no
            if (this.state.purchaserPhoneNumber.length < 10) {
                this.setState({
                    error: 'Please enter your cell phone number',
                });
                return;
            }

            //do we have an email address?
            //no
            if (this.state.purchaserEmailAddress.length < 6) {
                this.setState({
                    error: 'Please enter your email address',
                });
                return;
            }
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        // POST request using fetch with error handling
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                siteId: window.configuration.siteId,
                contactFirstName: this.state.contactFirstName,
                contactLastName: this.state.contactLastName,
                contactPhoneNumber: this.state.contactPhoneNumber,
                contactEmailAddress: this.state.contactEmailAddress,
                gift: this.state.gift,
                purchaserFirstName: this.state.purchaserFirstName,
                purchaserLastName: this.state.purchaserLastName,
                purchaserPhoneNumber: this.state.purchaserPhoneNumber,
                purchaserEmailAddress: this.state.purchaserEmailAddress,
                promoCode: this.state.promoCode,
                welcome: this.state.welcome,
                admins: this.state.adminPhone1 + '\n' + this.state.adminPhone2 + '\n' + this.state.adminPhone3,
                planName: this.state.privateLabelObject.value,
                maxSubscribers: this.state.privateLabelObject.maxSubscribers,
                maxMonthlyUpdates: this.state.privateLabelObject.maxMonthlyUpdates,
                photoUpdates: this.state.privateLabelObject.photoUpdates,
                planPricePerMonth: this.state.privateLabelObject.planPricePerMonth,
                privateLabelId: this.state.privateLabelObject.id,
            }),
        };

        fetch(window.configuration.SignUpURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //set the error
                    this.setState({
                        error:
                            'We were unable to create the ' +
                            this.state.privateLabelObject.brandName +
                            ' number. Try again.',
                        disabled: false,
                        loading: false,
                    });
                }
                //did we get an error?
                //no
                else {
                    var serverResponse = await response.json();

                    //did it work?
                    //yes
                    if (serverResponse.error === null) {
                        // POST request using fetch with error handling
                        const metricOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                siteId: window.configuration.siteId,
                                page: 'signup',
                                action: 'created',
                                queryString: this.state.contactFirstName + ' ' + this.state.contactLastName,
                            }),
                        };

                        fetch(window.configuration.MetricURL, metricOptions);

                        var qs = queryString.parse(this.props.location.search);

                        //send them to the verify page
                        this.props.history.push('/a/partner/verify?p=' + qs['p']);
                    }
                    //did it work?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: serverResponse.error,
                            disabled: false,
                            loading: false,
                        });
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    disabled: false,
                    loading: false,
                });
            });
    }

    render() {
        var qs = queryString.parse(this.props.location.search);
        return (
            <>
                {this.state.loading === true ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <div style={{ marginTop: 0, zIndex: 1000 }}>
                    <Row>
                        <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 10, offset: 1 }}>
                            <Form>
                                <Card style={{ padding: 30 }}>
                                    <CardHeader>
                                        <img
                                            src={this.state.privateLabelObject.logoURL}
                                            alt={this.state.privateLabelObject.brandName}
                                            style={{ maxWidth: 300 }}
                                        />
                                        <CardTitle tag="h4">
                                            Set up your {this.state.privateLabelObject.brandName} Number
                                        </CardTitle>
                                        Fill in the information below to get a new{' '}
                                        {this.state.privateLabelObject.brandName} number at no cost to you.
                                    </CardHeader>
                                    <CardBody>
                                        <Row style={{ marginBottom: 10 }}>
                                            <Col xs={12} md={12} style={{ marginTop: 10, marginBottom: 10 }}>
                                                <FormGroup className={'has-label ' + this.state.contactFirstNameState}>
                                                    Who are you signing up for a{' '}
                                                    {this.state.privateLabelObject.brandName} number?
                                                    <br />
                                                    <input
                                                        type="radio"
                                                        checked={this.state.gift === false}
                                                        style={{ marginTop: 10 }}
                                                        onChange={(e) => {
                                                            this.setState({ gift: false });
                                                        }}
                                                    />
                                                    <span
                                                        onClick={(e) => {
                                                            this.setState({ gift: false });
                                                        }}
                                                        style={{ paddingLeft: 5 }}
                                                    >
                                                        Me
                                                    </span>
                                                    <input
                                                        type="radio"
                                                        checked={this.state.gift === true}
                                                        style={{ marginLeft: 20, marginTop: 10 }}
                                                        onChange={(e) => {
                                                            this.setState({ gift: true });
                                                        }}
                                                    />
                                                    <span
                                                        onClick={(e) => {
                                                            this.setState({ gift: true });
                                                        }}
                                                        style={{ paddingLeft: 5 }}
                                                    >
                                                        Someone else
                                                    </span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.contactFirstNameState}>
                                                    {this.state.gift === true ? 'Their' : 'Your'} First Name *
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize', borderColor: 'silver' }}
                                                        maxLength="100"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                contactFirstName: e.target.value,
                                                                contactFirstNameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.contactFirstName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.contactLastNameState}>
                                                    {this.state.gift === true ? 'Their' : 'Your'} Last Name *
                                                    <Input
                                                        style={{ borderColor: 'silver' }}
                                                        type="text"
                                                        maxLength="100"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                contactLastName: e.target.value,
                                                                contactLastNameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.contactLastName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: 10 }}>
                                            <Col xs={12} md={4}>
                                                <FormGroup
                                                    className={'has-label ' + this.state.contactPhoneNumberState}
                                                >
                                                    {this.state.gift === true ? 'Their' : 'Your'} Cell Phone Number *
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="14"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                contactPhoneNumber: this.formatPhone(e.target.value),
                                                                contactPhoneNumberState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.contactPhoneNumber}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <FormGroup
                                                    className={'has-label ' + this.state.contactEmailAddressState}
                                                >
                                                    {this.state.gift === true ? 'Their' : 'Your'} Email Address *
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="250"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                contactEmailAddress: e.target.value,
                                                                contactEmailAddressState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.contactEmailAddress}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label'}>
                                                    {this.state.gift === true ? 'They' : 'You'} can add up to 3 other
                                                    people who can send updates. Enter their cell phone numbers below if
                                                    applicable.
                                                    {this.state.gift === true
                                                        ? ' Be sure to add yours if you want to send updates too.'
                                                        : ''}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <FormGroup className={'has-label'}>
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="14"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                adminPhone1: this.formatPhone(e.target.value),
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.adminPhone1}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <FormGroup className={'has-label'}>
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="14"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                adminPhone2: this.formatPhone(e.target.value),
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.adminPhone2}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <FormGroup className={'has-label'}>
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="14"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                adminPhone3: this.formatPhone(e.target.value),
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.adminPhone3}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: 10 }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label'}>
                                                    Adjust {this.state.gift === true ? 'their' : 'your'} message we send
                                                    when people subscribe to{' '}
                                                    {this.state.gift === true ? 'their' : 'your'}{' '}
                                                    {this.state.privateLabelObject.brandName} number *
                                                    <TextareaAutosize
                                                        style={{
                                                            width: '100%',
                                                            marginTop: 5,
                                                            padding: 10,
                                                            borderColor: 'silver',
                                                        }}
                                                        minRows={2}
                                                        maxRows={2}
                                                        className="react-select"
                                                        type="text"
                                                        maxLength="325"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                welcome: e.target.value,
                                                                welcomeState:
                                                                    e.target.value.length > 4
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        autoCorrect="true"
                                                        value={this.state.welcome}
                                                    />
                                                </FormGroup>
                                                <FormGroup style={{ textAlign: 'end', marginTop: -10 }}>
                                                    <Label style={{ fontSize: 10, paddingRight: 5 }}>
                                                        {this.state.welcome.length} characters of 325
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{ display: this.state.gift === true ? '' : 'none', paddingTop: 10 }}
                                        >
                                            <Col xs={12} md={6}>
                                                <FormGroup
                                                    className={'has-label ' + this.state.purchaserFirstNameState}
                                                >
                                                    Your First Name *
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize', borderColor: 'silver' }}
                                                        maxLength="100"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                purchaserFirstName: e.target.value,
                                                                purchaserFirstNameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.purchaserFirstName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.purchaserLastNameState}>
                                                    Your Last Name *
                                                    <Input
                                                        style={{ borderColor: 'silver' }}
                                                        type="text"
                                                        maxLength="100"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                purchaserLastName: e.target.value,
                                                                purchaserLastNameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.purchaserLastName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{ display: this.state.gift === true ? '' : 'none', paddingTop: 10 }}
                                        >
                                            <Col xs={12} md={4}>
                                                <FormGroup
                                                    className={'has-label ' + this.state.purchaserPhoneNumberState}
                                                >
                                                    Your Cell Phone Number *
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="14"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                purchaserPhoneNumber: this.formatPhone(e.target.value),
                                                                purchaserPhoneNumberState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.purchaserPhoneNumber}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <FormGroup
                                                    className={'has-label ' + this.state.purchaserEmailAddressState}
                                                >
                                                    Your Email Address *
                                                    <Input
                                                        type="text"
                                                        style={{ borderColor: 'silver' }}
                                                        maxLength="250"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                purchaserEmailAddress: e.target.value,
                                                                purchaserEmailAddressState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.purchaserEmailAddress}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: 0 }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label '}>
                                                    Your {this.state.privateLabelObject.brandName} number includes the
                                                    following:
                                                    <br />
                                                    <li style={{ marginLeft: 10 }}>
                                                        You can send up to{' '}
                                                        {this.state.privateLabelObject.maxMonthlyUpdates} updates per
                                                        month
                                                    </li>
                                                    <li style={{ marginLeft: 10 }}>
                                                        To up to {this.state.privateLabelObject.maxSubscribers} people
                                                    </li>
                                                    {this.state.privateLabelObject.addPhotos === true ? (
                                                        <li style={{ marginLeft: 10 }}>
                                                            {' '}
                                                            + 'You can include photos in your updates' +{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: 10 }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label'}>
                                                    By clicking the button below you agree to the{' '}
                                                    {this.state.privateLabelObject.brandName}
                                                    <a
                                                        href={'https://replyallhealth.com/terms'}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        {' '}
                                                        terms and conditions
                                                    </a>
                                                    .
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.error ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup style={{ textAlign: 'center' }}>
                                                    <Label
                                                        style={{
                                                            paddingTop: 20,
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            display: this.state.error ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.error}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            style={{
                                                backgroundColor: this.state.privateLabelObject.successColor,
                                                width: '100%',
                                                marginLeft: -5,
                                            }}
                                            onClick={(e) => this.submit(e)}
                                        >
                                            {this.state.disabled
                                                ? 'One moment...'
                                                : 'Sign up for ' +
                                                  (this.state.gift === true ? 'their ' : 'your ') +
                                                  this.state.privateLabelObject.brandName +
                                                  ' number'}
                                        </Button>
                                        <Label
                                            style={{
                                                width: '100%',
                                                paddingTop: 20,
                                                textAlign: 'center',
                                                display: this.state.gift === true ? 'none' : '',
                                            }}
                                        >
                                            Already have an account?{' '}
                                            <a href={'/a/partner/login?p=' + qs['p']} rel="noopener noreferrer">
                                                Log in here.
                                            </a>
                                        </Label>
                                        <Label style={{ width: '100%', paddingTop: 40, textAlign: 'right' }}>
                                            powered by{' '}
                                            <a
                                                href="https://replyallhealth.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                ReplyAll Health
                                            </a>
                                        </Label>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div
                    className="full-page-background"
                    style={{ backgroundColor: this.state.privateLabelObject.backgroundColor }}
                />
            </>
        );
    }
}

export default PartnerSignUpPage;
