/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Label, Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga';

const queryString = require('query-string');

var groupId = '';

class SubscriberList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            filtered: [],
            groupId: localStorage.getItem('groupId'),
            privateLabelObject: { id: null, brandName: 'ReplyAll Health' },
        };
    }

    //we get the data from the system on this call
    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            //start it out as the default
            groupId = localStorage.getItem('groupId');

            var privateLabel = JSON.parse(localStorage.getItem('privateLabel'));
            this.setState({
                privateLabelObject: privateLabel !== null ? privateLabel : this.state.privateLabelObject,
            });

            // GET request using fetch with error handling
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    SECRET: localStorage.getItem('SECRET'),
                    PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                },
            };

            fetch(window.configuration.GroupURL, requestOptions)
                .then(async (response) => {
                    //did we get an error?
                    //yes
                    if (!response.ok) {
                        //did we get an unauthorized?
                        //yes
                        if (response.status === 401) {
                            this.props.history.push('/a/logout');
                        }
                        return null;
                    }
                    //did we get an error?
                    //no
                    else {
                        var group = await response.json();

                        //do we have any members?
                        //yes
                        if (group.members && group.members.length > 0) {
                            //create our rows
                            var memberObjects = group.members.map(function (member) {
                                return {
                                    id: member.id,
                                    phoneNumber:
                                        '(' +
                                        member.phoneNumber.slice(0, 3) +
                                        ') ' +
                                        member.phoneNumber.slice(3, 6) +
                                        '-' +
                                        member.phoneNumber.slice(6, 10),
                                    doNotSMS:
                                        member.emailAddress !== '' && member.emailAddress !== null ? (
                                            <i />
                                        ) : member.doNotSMS === true ? (
                                            <i
                                                className="fa fa-comment-slash"
                                                data-tip="Unsubscribed"
                                                style={{ color: '#bd1e2c' }}
                                            />
                                        ) : (
                                            <i
                                                className="fa fa-comment"
                                                data-tip="Subscribed"
                                                style={{ color: '#1aafc4' }}
                                            />
                                        ),
                                    innerCircle:
                                        member.innerCircle === true ? (
                                            <i
                                                className="fa fa-user-circle"
                                                data-tip="Inner Circle"
                                                style={{ color: '#1aafc4' }}
                                            />
                                        ) : (
                                            <i />
                                        ),
                                    name:
                                        member.firstName !== null && member.lastName !== null
                                            ? member.firstName + ' ' + member.lastName
                                            : member.firstName !== null && member.lastName == null
                                            ? member.firstName
                                            : member.firstName == null && member.lastName !== null
                                            ? member.lastName
                                            : '',
                                    emailAddress: member.emailAddress,
                                    dateAdded:
                                        new Date(member.dateAdded).toLocaleDateString() +
                                        ' ' +
                                        new Date(member.dateAdded).toLocaleTimeString(),
                                    actions: (
                                        // we've added some custom button actions
                                        <div className="actions-right">
                                            <ReactTooltip />
                                            <Link to={'/in/f/subscriber?id=' + member.id}>
                                                <Button
                                                    data-tip="edit"
                                                    className="btn-icon btn-round"
                                                    style={{ backgroundColor: '#1aafc4' }}
                                                    size="sm"
                                                >
                                                    <i className="fa fa-edit" />
                                                </Button>
                                            </Link>
                                            {/* spacer between buttons */ ' '}
                                            <Button
                                                data-tip="remove"
                                                onClick={() => {
                                                    //confirm that they want to remove it
                                                    confirmAlert({
                                                        closeOnEscape: true,
                                                        closeOnClickOutside: true,
                                                        onClickOutside: () => {},
                                                        onKeypressEscape: () => {},
                                                        willUnmount: () => {},
                                                        afterClose: () => {},
                                                        message: 'Are you sure you want to remove this subscriber?',
                                                        buttons: [
                                                            {
                                                                label: 'Yes',
                                                                onClick: () => {
                                                                    // DELETE request using fetch with error handling
                                                                    const requestOptions = {
                                                                        method: 'DELETE',
                                                                        headers: {
                                                                            'Content-Type': 'application/json',
                                                                            SECRET: localStorage.getItem('SECRET'),
                                                                            PASSPHRASE: localStorage.getItem(
                                                                                'PASSPHRASE',
                                                                            ),
                                                                        },
                                                                    };

                                                                    fetch(
                                                                        window.configuration.MemberURL +
                                                                            '?groupId=' +
                                                                            groupId +
                                                                            '&memberPhone=' +
                                                                            member.phoneNumber,
                                                                        requestOptions,
                                                                    )
                                                                        .then(async (response) => {
                                                                            //refresh the page
                                                                            window.location.reload();
                                                                        })
                                                                        .catch((error) => {
                                                                            console.log(error);
                                                                            //refresh the page
                                                                            window.location.reload();
                                                                        });
                                                                },
                                                            },
                                                            {
                                                                label: 'No',
                                                            },
                                                        ],
                                                    });
                                                }}
                                                className="btn-icon btn-round"
                                                style={{ backgroundColor: '#bd1e2c' }}
                                                size="sm"
                                            >
                                                <i className="fa fa-times" />
                                            </Button>
                                        </div>
                                    ),
                                };
                            });
                            //grab our response
                            this.setState({
                                data: memberObjects,
                                loading: false,
                            });
                        } else {
                            this.setState({
                                error: 'error',
                                loading: false,
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.setState({
                        error: error,
                        loading: false,
                    });
                    return null;
                });
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter['value'] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    };

    render() {
        //are we loading?
        //yes
        if (this.state.loading === true) {
            return (
                <>
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </>
            );
        }
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 950 }}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Subscribers</CardTitle>
                                    The following people have signed up as a subscriber to your updates.{' '}
                                    <Link style={{ color: '#1aafc4' }} to={'/in/f/subscriber'}>
                                        Add a new subscriber
                                    </Link>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.state.data}
                                        filterable={true}
                                        filtered={this.state.filtered}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        defaultFilterMethod={(filter, row, column) => {
                                            const id = filter.pivotId || filter.id;
                                            if (typeof filter.value === 'object') {
                                                return row[id] !== undefined
                                                    ? filter.value.indexOf(row[id]) > -1
                                                    : true;
                                            } else {
                                                return row[id] !== undefined
                                                    ? String(row[id]).indexOf(filter.value) > -1
                                                    : true;
                                            }
                                        }}
                                        columns={[
                                            {
                                                Header: 'Phone Number',
                                                accessor: 'phoneNumber',
                                                width: 140,
                                                filterable: false,
                                                sortable: this.state.data.length == 0 ? false : true,
                                            },
                                            {
                                                Header: 'Name',
                                                accessor: 'name',
                                                width: 200,
                                                filterable: false,
                                                sortable: this.state.data.length == 0 ? false : true,
                                            },
                                            {
                                                Header: 'Email',
                                                accessor: 'emailAddress',
                                                width: 200,
                                                filterable: false,
                                                sortable: this.state.data.length == 0 ? false : true,
                                            },
                                            {
                                                Header: 'Date Added',
                                                accessor: 'dateAdded',
                                                sortable: this.state.data.length == 0 ? false : true,
                                                filterable: false,
                                                //sort the dates
                                                sortMethod: (a, b) => {
                                                    a = new Date(a).getTime();
                                                    b = new Date(b).getTime();
                                                    return b > a ? 1 : -1;
                                                },
                                                width: 160,
                                            },
                                            {
                                                Header: '',
                                                accessor: 'doNotSMS',
                                                sortable: false,
                                                filterable: false,
                                                width: 40,
                                            },
                                            {
                                                Header: '',
                                                accessor: 'innerCircle',
                                                sortable: false,
                                                filterable: false,
                                                width: 40,
                                            },
                                            {
                                                Header: '',
                                                accessor: 'actions',
                                                sortable: false,
                                                filterable: false,
                                            },
                                        ]}
                                        defaultPageSize={this.state.data.length > 0 ? this.state.data.length : 0}
                                        //NoDataComponent="There are no subscribers"
                                        showPaginationBottom={false}
                                        className="-striped -highlight"
                                    />
                                    <Label
                                        style={{
                                            fontSize: 14,
                                            marginTop: 10,
                                            paddingLeft: 5,
                                            display: this.state.data.length === 0 ? '' : 'none',
                                        }}
                                    >
                                        You do not have any subscribers.
                                        <br />
                                        <br />
                                        <Button
                                            style={{ backgroundColor: '#1aafc4' }}
                                            onClick={() => {
                                                this.props.history.push('/in/f/subscriber');
                                            }}
                                        >
                                            Add your first subscriber
                                        </Button>
                                    </Label>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default SubscriberList;
