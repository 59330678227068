/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
    Input,
    Form,
    FormGroup,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import PhoneInput from 'react-phone-input-2';

const queryString = require('query-string');

var companyId = '';

class EmployeeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeId: '',
            firstName: '',
            firstNameState: '',
            lastName: '',
            lastNameState: '',
            department: '',
            departmentState: '',
            phoneNumber: '',
            phoneNumberState: '',
            emailAddress: '',
            emailAddressState: '',
            disabled: false,
            error: '',
            loading: true,
        };
    }

    //this handles the required stuff
    emailAddressRequired(e) {
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        //do we have a good email?
        //no
        if (reg.test(e.target.value) === false) {
            //set it to success
            //set the error to nothing
            this.setState({
                emailAddress: e.target.value,
                emailAddressState: 'has-danger',
                error: 'Please enter a valid email address',
            });
        }
        //do we have a good email?
        //yes
        else {
            //set it to success
            //set the error to nothing
            this.setState({
                emailAddress: e.target.value,
                emailAddressState: e.target.value.length > 1 ? 'has-success' : 'has-danger',
                error: '',
            });
        }
    }

    phoneUpdated(phone) {
        let reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (reg.test(phone.substring(1)) === false) {
            this.setState({
                phoneNumber: phone,
                phoneNumberState: 'has-danger',
            });
        } else {
            this.setState({
                phoneNumber: phone,
                phoneNumberState: 'has-success',
            });
        }
    }

    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            var qs = queryString.parse(this.props.location.search);

            //start it out as the default
            companyId = localStorage.getItem('companyId');

            //do we have a company id coming in on the querystring?
            //yes
            if (qs['companyId']) {
                //set the one from the querystring
                companyId = qs['companyId'];
            }

            //do we have a group id?
            //yes
            if (qs['id']) {
                //get the group from the server
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        SECRET: localStorage.getItem('SECRET'),
                        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                    },
                };

                fetch(window.configuration.EmployeeURL + '?companyId=' + companyId + '&id=' + qs['id'], requestOptions)
                    .then(async (response) => {
                        // check for error response
                        //did we get an error?
                        //yes
                        if (!response.ok) {
                            //did we get an unauthorized?
                            //yes
                            if (response.status === 401) {
                                this.props.history.push('/a/logout');
                            }
                            //did we get an unauthorized?
                            //no
                            else {
                                //set the error
                                this.setState({
                                    error: 'We were unable to edit the employee',
                                    disabled: true,
                                    loading: false,
                                });
                            }
                        }
                        //did we get an error?
                        //no
                        else {
                            var employeeResponse = await response.json();

                            //did we get a good one?
                            //yes
                            if (employeeResponse.id) {
                                let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

                                this.setState({
                                    error: '',
                                    loading: false,
                                    disabled: false,
                                    employeeId: employeeResponse.id,
                                    firstName: employeeResponse.firstName,
                                    firstNameState: 'has-success',
                                    lastName: employeeResponse.lastName,
                                    lastNameState: 'has-success',
                                    phoneNumber: employeeResponse.phoneNumber,
                                    phoneNumberState: 'has-success',
                                    emailAddress: employeeResponse.emailAddress,
                                    emailAddressState:
                                        reg.test(employeeResponse.emailAddress) === true ? 'has-success' : 'has-danger',
                                    department: employeeResponse.department,
                                    departmentState: employeeResponse.department ? 'has-success' : 'has-danger',
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            error: error,
                            disabled: false,
                            loading: false,
                        });
                    });
            }
            //do we have a group id?
            //no
            else {
                this.setState({ loading: false });
            }
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e.key === 'Enter') {
                this.submitForm(null, null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //this submits the form
    submit(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a first name?
        //no
        if (this.state.firstName.length === 0) {
            this.setState({
                error: 'Please enter their first name',
            });
            return;
        }
        //do we have a last name?
        //no
        else if (this.state.lastName.length === 0) {
            this.setState({
                error: 'Please enter their last name',
            });
            return;
        }
        //do we have an email address?
        //no
        else if (this.state.emailAddress) {
            let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (reg.test(this.state.emailAddress) === false) {
                this.setState({
                    error: 'Please enter a valid email address',
                });
                return;
            }
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        // POST request using fetch with error handling
        const requestOptions = {
            method: this.state.id ? 'PATCH' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
            body: JSON.stringify({
                //we set id because this is the employee record
                id: this.state.employeeId,
                siteId: window.configuration.siteId,
                companyId: companyId,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                department: this.state.department,
                emailAddress: this.state.emailAddress,
            }),
        };

        fetch(window.configuration.EmployeeURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: 'We were unable to add the employee',
                            disabled: false,
                            loading: false,
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    var qs = queryString.parse(this.props.location.search);

                    //send them to the employees
                    this.props.history.push(
                        '/in/l/employees?companyId=' + companyId + '&returnAfter=' + qs['returnAfter'],
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    disabled: false,
                    loading: false,
                });
            });
    }

    render() {
        var qs = queryString.parse(this.props.location.search);

        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 900 }}>
                            <Form>
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            {this.state.employeeId ? 'Update an employee' : 'Add an employee'}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.firstState}>
                                                    <Label>First Name *</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize' }}
                                                        maxLength="50"
                                                        ref={(input) => {
                                                            this.firstNameInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                firstName: e.target.value,
                                                                firstNameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.firstName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.lastNameState}>
                                                    <Label>Last Name *</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize' }}
                                                        maxLength="100"
                                                        ref={(input) => {
                                                            this.lastNameInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                lastName: e.target.value,
                                                                lastNameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.lastName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.departmentState}>
                                                    <Label>Department</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize' }}
                                                        maxLength="100"
                                                        ref={(input) => {
                                                            this.departmentInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                department: e.target.value,
                                                                departmentState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.department}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.emailAddressState}>
                                                    <Label>Email Address *</Label>
                                                    <Input
                                                        type="text"
                                                        maxLength="100"
                                                        ref={(input) => {
                                                            this.emailAddressInput = input;
                                                        }}
                                                        onChange={(e) => this.emailAddressRequired(e)}
                                                        onKeyDown={this.handleKeyDown}
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.emailAddress}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.phoneNumberState}>
                                                    <Label>Phone Number *</Label>
                                                    <PhoneInput
                                                        autoFocus
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,
                                                        }}
                                                        placeholder="(512) 559-1228"
                                                        country={'us'}
                                                        regions={'north-america'}
                                                        disableDropdown={true}
                                                        disableCountryCode={true}
                                                        countryCodeEditable={true}
                                                        onKeyDown={this._handleKeyDown}
                                                        inputStyle={{
                                                            fontSize: 16,
                                                            height: 40,
                                                            paddingLeft: 50,
                                                            backgroundColor: '#FFFFFF',
                                                            color: '#000000',
                                                            width: '100%',
                                                        }}
                                                        value={this.state.phoneNumber}
                                                        onChange={(text) => this.phoneUpdated(text)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={10}>
                                                <FormGroup
                                                    style={{ textAlign: 'end', display: this.state.error ? '' : '' }}
                                                >
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            paddingRight: 15,
                                                            display: this.state.error ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.error}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-right">
                                        <Button color="success" onClick={(e) => this.submit(e)}>
                                            {this.state.disabled
                                                ? 'One moment...'
                                                : this.state.employeeId
                                                ? 'Update employee'
                                                : 'Add employee'}
                                        </Button>
                                        {/* spacer between buttons */ ' '}
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            color="secondary"
                                            onClick={() => {
                                                this.props.history.push(
                                                    '/in/l/employees?companyId=' +
                                                        companyId +
                                                        '&returnAfter=' +
                                                        qs['returnAfter'],
                                                );
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default EmployeeForm;
