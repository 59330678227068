/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// reactstrap components
import React from 'react';

import {
    Input,
    Form,
    FormGroup,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import ReactGA from 'react-ga';

const queryString = require('query-string');

class PartnerVerifyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            codeState: '',
            disabled: false,
            error: '',
            privateLabelObject: { id: null, brandName: '' },
            loading: false,
        };

        this.reactTags = React.createRef();
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        var qs = queryString.parse(this.props.location.search);

        // POST request using fetch with error handling
        const metricOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                siteId: window.configuration.siteId,
                page: 'signup',
                action: 'loaded',
                queryString: this.props.location.search,
            }),
        };

        //do we have a private label coming in?
        //yes
        if (qs['p']) {
            // GET request using fetch with error handling
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            fetch(window.configuration.PrivateLabelURL + '?urlCode=' + qs['p'].toUpperCase(), requestOptions)
                .then(async (response) => {
                    // check for error response
                    //did we get an error?
                    //no
                    if (response.ok) {
                        var serverResponse = await response.json();

                        //did it work?
                        //yes
                        if (serverResponse.id !== null) {
                            this.setState({
                                error: '',
                                privateLabelObject: serverResponse,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        //do we have a private label coming in?
        //no
        else {
            this.props.history.push('/a/verify');
            return;
        }

        fetch(window.configuration.MetricURL, metricOptions);
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e.key === 'Enter') {
                this.submit(null, null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //this submits the form
    submit(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a code?
        //no
        if (this.state.code.length < 6) {
            this.setState({
                error: 'Please enter the code we emailed you',
            });
            return;
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        var qs = queryString.parse(this.props.location.search);

        // POST request using fetch with error handling
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        fetch(window.configuration.SignUpURL + '?code=' + this.state.code, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/partner/verify?p=' + qs['p']);
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: 'We were unable to verify your code',
                            disabled: false,
                            loading: false,
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    //grab our response
                    var smsAuthResponse = await response.json();

                    //did we get a good response?
                    //no
                    if (smsAuthResponse.success === false) {
                        //hide the phone number and show the code field
                        this.setState({
                            error: smsAuthResponse.error,
                            disabled: false,
                            loading: false,
                        });
                    }
                    //did we get a good response?
                    //yes
                    else if (smsAuthResponse.success === true) {
                        //did we get a good employee or member back yet?
                        //no
                        if (!smsAuthResponse.employeeId && !smsAuthResponse.phoneNumber) {
                            //hide the phone number and show the code field
                            this.setState({
                                loading: false,
                                error: 'We were unable to verify your email address.',
                            });
                        }
                        //did we get a good employee or member back yet?
                        //yes
                        else {
                            //do we have a promotional account?
                            //yes
                            if (smsAuthResponse.promotional) {
                                localStorage.setItem('discountTier1', smsAuthResponse.promotional.discountTier1);
                            }
                            //do we have a promotional account?
                            //no
                            else {
                                localStorage.setItem('discountTier1', 0.0);
                            }

                            //do we have a promotional account?
                            //yes
                            if (smsAuthResponse.promotional) {
                                localStorage.setItem('discountTier2', smsAuthResponse.promotional.discountTier2);
                            }
                            //do we have a promotional account?
                            //no
                            else {
                                localStorage.setItem('discountTier2', 0.0);
                            }

                            //do we have a promotional account?
                            //yes
                            if (smsAuthResponse.promotional) {
                                localStorage.setItem('discountTier3', smsAuthResponse.promotional.discountTier3);
                            }
                            //do we have a promotional account?
                            //no
                            else {
                                localStorage.setItem('discountTier3', 0.0);
                            }

                            //do we have a groupAdmin flag?
                            //yes
                            if (smsAuthResponse.groupAdminFlag && smsAuthResponse.groupAdminFlag === true) {
                                localStorage.setItem('groupAdminFlag', smsAuthResponse.groupAdminFlag);
                            }
                            //do we have a member flag?
                            //no
                            else {
                                localStorage.removeItem('groupAdminFlag');
                            }

                            //do we have an systemAdmin Flag?
                            //yes
                            if (smsAuthResponse.systemAdminFlag && smsAuthResponse.systemAdminFlag === true) {
                                localStorage.setItem('systemAdminFlag', smsAuthResponse.systemAdminFlag);
                            }
                            //do we have an adminFlag?
                            //no
                            else {
                                localStorage.removeItem('systemAdminFlag');
                            }

                            //do we have a company id?
                            //yes
                            if (smsAuthResponse.companyId) {
                                localStorage.setItem('companyId', smsAuthResponse.companyId);
                            }
                            //do we have a company id?
                            //no
                            else {
                                localStorage.removeItem('companyId');
                            }

                            //do we have a company name?
                            //yes
                            if (smsAuthResponse.companyName) {
                                localStorage.setItem('companyName', smsAuthResponse.companyName);
                            }
                            //do we have a company id?
                            //no
                            else {
                                localStorage.removeItem('companyName');
                            }

                            //do we have a group id?
                            //yes
                            if (smsAuthResponse.groupId) {
                                localStorage.setItem('groupId', smsAuthResponse.groupId);
                            }
                            //do we have a group id?
                            //no
                            else {
                                localStorage.removeItem('groupId');
                            }

                            //do we have a smsPhoneNumber?
                            //yes
                            if (smsAuthResponse.smsPhoneNumber) {
                                localStorage.setItem('smsPhoneNumber', smsAuthResponse.smsPhoneNumber);
                            }
                            //do we have a smsPhoneNumber?
                            //no
                            else {
                                localStorage.removeItem('smsPhoneNumber');
                            }

                            //do we have an employee id?
                            //yes
                            if (smsAuthResponse.employeeId) {
                                localStorage.setItem('employeeId', smsAuthResponse.employeeId);
                            }
                            //do we have an employee id?
                            //no
                            else {
                                localStorage.removeItem('employeeId');
                            }

                            //do we have a firstName?
                            //yes
                            if (smsAuthResponse.firstName) {
                                localStorage.setItem('firstName', smsAuthResponse.firstName);
                            }
                            //do we have a firstName?
                            //no
                            else {
                                localStorage.removeItem('firstName');
                            }

                            //do we have a lastName?
                            //yes
                            if (smsAuthResponse.lastName) {
                                localStorage.setItem('lastName', smsAuthResponse.lastName);
                            }
                            //do we have a lastName?
                            //no
                            else {
                                localStorage.removeItem('lastName');
                            }

                            //do we have a private label?
                            //yes
                            if (smsAuthResponse.privateLabel) {
                                localStorage.setItem('privateLabel', JSON.stringify(smsAuthResponse.privateLabel));
                            }
                            //do we have a private label?
                            //no
                            else {
                                localStorage.removeItem('privateLabel');
                            }

                            localStorage.setItem('LOGGEDIN', 'true');
                            localStorage.setItem('PHONENUMBER', this.state.phoneNumber);

                            localStorage.setItem('SECRET', smsAuthResponse.secret);
                            localStorage.setItem('PASSPHRASE', smsAuthResponse.passPhrase);
                            localStorage.setItem('DATEEXPIRES', smsAuthResponse.dateExpires);

                            // POST request using fetch with error handling
                            const metricOptions = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    siteId: window.configuration.siteId,
                                    page: 'verify',
                                    action: 'account verified',
                                    queryString: JSON.stringify(smsAuthResponse),
                                }),
                            };

                            fetch(window.configuration.MetricURL, metricOptions);

                            //send them to the send an dashboard page
                            this.props.history.push('/in/f/dashboard');
                        }
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    disabled: false,
                    loading: false,
                });
            });
    }

    render() {
        var qs = queryString.parse(this.props.location.search);
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <div style={{ marginTop: -150, zIndex: 1000 }}>
                    <Row>
                        <Col xs={12} md={{ size: 'auto' }}>
                            <Form>
                                <Card style={{ paddingTop: 10, paddingLeft: 30, paddingRight: 30, paddingBottom: 30 }}>
                                    <CardHeader>
                                        <center>
                                            <img
                                                src={this.state.privateLabelObject.logoURL}
                                                alt={this.state.privateLabelObject.brandName}
                                                style={{ maxWidth: 300 }}
                                            />
                                        </center>
                                        <CardTitle tag="h4">Verify your email address</CardTitle>
                                        Check your email for a verification code.
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label'}>
                                                    <Input
                                                        autoFocus={true}
                                                        style={{
                                                            borderColor: 'silver',
                                                            paddingLeft: 10,
                                                            fontSize: 18,
                                                            height: 45,
                                                            backgroundColor: '#FFFFFF',
                                                            color: '#000000',
                                                            textAlign: 'center',
                                                            maxWidth: 300,
                                                            minWidth: 300,
                                                        }}
                                                        type="text"
                                                        placeholder="123456"
                                                        maxLength="6"
                                                        value={this.state.code ? this.state.code : ''}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                code: e.target.value,
                                                                codeState:
                                                                    e.target.value.length === 6
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.error ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup style={{ textAlign: 'center' }}>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            display: this.state.error ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.error}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Button
                                                    style={{ backgroundColor: '#ec1c23', width: 300, marginLeft: -5 }}
                                                    onClick={(e) => this.submit(e)}
                                                >
                                                    {this.state.disabled ? 'One moment...' : 'Verify my email address'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter style={{ textAlign: 'center' }}>
                                        <Label>
                                            Already have an account?{' '}
                                            <a href={'/a/partner/login?p=' + qs['p']} rel="noopener noreferrer">
                                                Log in here.
                                            </a>
                                        </Label>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default PartnerVerifyPage;
