/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var privateLabel = {};
        try {
            ReactGA.pageview(window.location.pathname + window.location.search);

            privateLabel = JSON.parse(localStorage.getItem('privateLabel'));

            //clear the global settings
            localStorage.clear();
        } catch (error) {
            console.log(error);
        }

        //do we have a private label?
        //no
        if (!privateLabel || privateLabel.id === null)
        {
            //redirect them to the login page
            return <Redirect to="/a/login?loggedout" />;
        }
        //do we have a private label?
        //yes
        else
        {
            //redirect them to the login page
            return <Redirect to={"/a/partner/login?loggedout&p=" + privateLabel.urlCode} />;
        }
    }
}

export default LogoutPage;
