/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';

// reactstrap components
import { Collapse, Navbar, Container } from 'reactstrap';

class AdminNavbar extends React.Component {
    state = {
        isOpen: false,
        dropdownOpen: false,
        color: 'transparent',
    };

    sidebarToggle = React.createRef();

    toggle = () => {
        if (this.state.isOpen) {
            this.setState({
                color: 'transparent',
            });
        } else {
            this.setState({
                color: 'white',
            });
        }
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    dropdownToggle = (e) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };

    openSidebar = () => {
        document.documentElement.classList.toggle('nav-open');
        this.sidebarToggle.current.classList.toggle('toggled');
    };

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: 'white',
            });
        } else {
            this.setState({
                color: 'transparent',
            });
        }
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateColor.bind(this));
    }

    componentDidUpdate(e) {
        if (
            window.innerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf('nav-open') !== -1
        ) {
            document.documentElement.classList.toggle('nav-open');
            this.sidebarToggle.current.classList.toggle('toggled');
        }
    }

    render() {
        return (
            // add or remove classes depending if we are on full-screen-maps page or not
            <Navbar
                color={window.location.href.indexOf('full-screen-maps') !== -1 ? 'white' : this.state.color}
                expand="lg"
                className={
                    window.location.href.indexOf('full-screen-maps') !== -1
                        ? 'navbar-absolute '
                        : 'navbar-absolute ' + (this.state.color === 'transparent' ? 'navbar-transparent ' : '')
                }
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-toggle">
                            <button
                                type="button"
                                ref={this.sidebarToggle}
                                className="navbar-toggler"
                                onClick={() => this.openSidebar()}
                            >
                                <span className="navbar-toggler-bar bar1" />
                                <span className="navbar-toggler-bar bar2" />
                                <span className="navbar-toggler-bar bar3" />
                            </button>
                        </div>
                    </div>
                    <Collapse isOpen={this.state.isOpen} navbar className="justify-content-end"></Collapse>
                </Container>
            </Navbar>
        );
    }
}

AdminNavbar.defaultProps = {
    brandText: 'ReplyAll Health',
};

AdminNavbar.propTypes = {
    // string for the page name
    brandText: PropTypes.string,
};

export default AdminNavbar;
