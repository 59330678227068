/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import { Form, FormGroup, Label, Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import TextareaAutosize from 'react-textarea-autosize';

const queryString = require('query-string');

class DashboardForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupId: '',
            disabled: false,
            redirectAfter: '',
            error: '',
            phoneNumber: '',
            phoneNumberState: '',
            text: '',
            copied: '',
            privateLabelObject: { id: null, brandName: 'ReplyAll Health' },
        };
    }

    formatPhone(value) {
        // return nothing if no value
        if (!value) {
            return value;
        }

        // only allows 0-9 inputs
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        // returns: "x", "xx", "xxx"
        if (cvLength < 4) {
            return currentValue;
        }
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        else if (cvLength < 7) {
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        } else {
            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    }

    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            //grab the querystring
            var qs = queryString.parse(this.props.location.search);

            var privateLabelString = localStorage.getItem('privateLabel');
            var privateLabel = null;

            if (privateLabelString) {
                privateLabel = JSON.parse(privateLabelString);
            }

            this.setState({
                groupId: localStorage.getItem('groupId'),
                //set this to tell us where to go after
                redirectAfter: qs['redirectAfter'] ? qs['redirectAfter'] : '',
                loading: false,
                text:
                    'Thank you for your support on my health journey. I have signed up for a ' +
                    (privateLabel ? privateLabel.brandName : '') +
                    " number. This allows me to send health updates to those that are subscribed to updates on this phone number. Please send a text with the word 'Hi' to " +
                    this.formatPhone(localStorage.getItem('smsPhoneNumber')) +
                    ' to subscribe. Thank you!',
                privateLabelObject: privateLabel !== null ? privateLabel : this.state.privateLabelObject,
            });
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    phoneUpdated(phone) {
        let reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (reg.test(phone.substring(1)) === false) {
            this.setState({
                phoneNumber: phone,
                phoneNumberState: 'has-danger',
            });
        } else {
            this.setState({
                phoneNumber: phone,
                phoneNumberState: 'has-success',
            });
        }
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e.key === 'Enter') {
                this.submitForm(null, null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //this submits the form
    submitForm(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a phone number?
        //no
        if (this.state.phoneNumber.length < 10) {
            this.setState({
                error: 'Enter the full number with area code',
            });
            return;
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        // POST request using fetch with error handling
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
            body: JSON.stringify({
                siteId: window.configuration.siteId,
                groupId: this.state.groupId,
                phoneNumber: this.state.phoneNumber,
            }),
        };

        fetch(window.configuration.MemberURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: 'We were unable to add the subscriber',
                            disabled: false,
                            loading: false,
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    var results = await response.json();

                    //did it work?
                    //yes
                    if (results.success === true) {
                        //send them to the subscribers list unless we have a different location
                        this.props.history.push(
                            this.state.redirectAfter ? this.state.redirectAfter : '/in/l/subscribers',
                        );
                        return;
                    }
                    //did it work?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: results.error,
                            disabled: false,
                            loading: false,
                        });
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    disabled: false,
                    loading: false,
                });
            });
    }

    render() {
        //are we loading?
        //yes
        if (this.state.loading === true) {
            return (
                <>
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </>
            );
        }
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 1000 }}>
                            <Form>
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            Welcome to the {this.state.privateLabelObject.brandName} dashboard!
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={12}
                                                style={{ marginTop: -10 }}
                                                onClick={() => {
                                                    this.setState({ copied: 'Copied to clipboard' });
                                                    navigator.clipboard.writeText(this.state.text);
                                                }}
                                            >
                                                <FormGroup className={'has-label'}>
                                                    <Label
                                                        onClick={() => {
                                                            this.setState({ copied: 'Copied to clipboard' });
                                                            navigator.clipboard.writeText(this.state.text);
                                                        }}
                                                        style={{ fontSize: 14, color: 'black' }}
                                                    >
                                                        Let your friends and family subscribe by sharing this text on
                                                        Facebook, email, and text. Click here to copy it your clipboard{' '}
                                                        <i className="fa fa-clipboard"></i>.
                                                    </Label>
                                                    <TextareaAutosize
                                                        style={{ width: '100%', padding: 10, borderColor: 'silver' }}
                                                        minRows={3}
                                                        maxRows={4}
                                                        disabled={true}
                                                        className="react-select"
                                                        type="text"
                                                        onClick={() => {
                                                            this.setState({ copied: 'Copied to clipboard' });
                                                            navigator.clipboard.writeText(this.state.text);
                                                        }}
                                                        value={this.state.text}
                                                    />
                                                </FormGroup>
                                                <FormGroup
                                                    style={{ marginTop: -15, display: this.state.copied ? '' : 'none' }}
                                                >
                                                    <Label style={{ color: 'black', fontSize: 10, paddingRight: 5 }}>
                                                        {this.state.copied}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label'}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: this.state.privateLabelObject.successColor,
                                                            fontSize: 13,
                                                        }}
                                                        onClick={() => {
                                                            this.props.history.push('/in/f/subscriber');
                                                        }}
                                                    >
                                                        Add subscribers to your{' '}
                                                        {this.state.privateLabelObject.brandName} number
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ paddingTop: 20 }}>
                                                <FormGroup className={'has-label'}>
                                                    <h5>Text Message Commands</h5>
                                                    The following commands are available to you by sending a text
                                                    message from your phone to{' '}
                                                    {this.formatPhone(localStorage.getItem('smsPhoneNumber'))}.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                display:
                                                    this.state.privateLabelObject.addSubscribers !== false &&
                                                    localStorage.getItem('consumptionBased') === 'false'
                                                        ? ''
                                                        : 'none',
                                            }}
                                        >
                                            <Col xs={12} md={12} style={{ paddingTop: 10, display: 'none' }}>
                                                <FormGroup className={'has-label'}>
                                                    <h6>add 5</h6>
                                                    Sending a text message with the command <i>"add 5"</i> to{' '}
                                                    {this.formatPhone(localStorage.getItem('smsPhoneNumber'))} will add
                                                    5 more subscriber slots to your account for an extra $2.99 per
                                                    month.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                display:
                                                    localStorage.getItem('consumptionBased') === 'true' ? '' : 'none',
                                            }}
                                        >
                                            <Col xs={12} md={12} style={{ paddingTop: 10 }}>
                                                <FormGroup className={'has-label'}>
                                                    <h6>add funds</h6>
                                                    Sending a text message with the command <i>"add funds"</i> to{' '}
                                                    {this.formatPhone(localStorage.getItem('smsPhoneNumber'))} will add
                                                    $20 to your account balance.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                display:
                                                    this.state.privateLabelObject.addPhotos !== false &&
                                                    localStorage.getItem('consumptionBased') === 'false'
                                                        ? ''
                                                        : 'none',
                                            }}
                                        >
                                            <Col xs={12} md={12} style={{ paddingTop: 10, display: 'none' }}>
                                                <FormGroup className={'has-label'}>
                                                    <h6>add photos</h6>
                                                    Sending a text message with the command <i>"add photos"</i> to{' '}
                                                    {this.formatPhone(localStorage.getItem('smsPhoneNumber'))} will add
                                                    the ability to add photos to your updates for an extra $14.99 per
                                                    month.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                display:
                                                    localStorage.getItem('consumptionBased') === 'true' ? '' : 'none',
                                            }}
                                        >
                                            <Col xs={12} md={12} style={{ paddingTop: 10 }}>
                                                <FormGroup className={'has-label'}>
                                                    <h6>balance</h6>
                                                    Sending a text message with the command <i>"balance"</i> to{' '}
                                                    {this.formatPhone(localStorage.getItem('smsPhoneNumber'))} will
                                                    return your current account balance.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ paddingTop: 15 }}>
                                                <FormGroup className={'has-label'}>
                                                    <h6>cancel update</h6>
                                                    Sending a text message with the command <i>
                                                        "cancel update"
                                                    </i> to {this.formatPhone(localStorage.getItem('smsPhoneNumber'))}{' '}
                                                    will stop your previous update from going out if you send it within
                                                    10 minutes of publishing it.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ paddingTop: 15 }}>
                                                <FormGroup className={'has-label'}>
                                                    <h6>close</h6>
                                                    Sending a text message with the command <i>"close"</i> to{' '}
                                                    {this.formatPhone(localStorage.getItem('smsPhoneNumber'))} will stop
                                                    people from joining by texting 'Hi'. You can still add people
                                                    through this website.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ paddingTop: 15 }}>
                                                <FormGroup className={'has-label'}>
                                                    <h6>commands</h6>
                                                    Sending a text message with the command <i>"commands"</i> to{' '}
                                                    {this.formatPhone(localStorage.getItem('smsPhoneNumber'))} will send
                                                    you the list of text commands you can use with the system.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ paddingTop: 15 }}>
                                                <FormGroup className={'has-label'}>
                                                    <h6>count</h6>
                                                    Sending a text message with the command <i>"count"</i> to{' '}
                                                    {this.formatPhone(localStorage.getItem('smsPhoneNumber'))} will have
                                                    the system reply with the number of subscribers currently receiving
                                                    your updates.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ paddingTop: 15 }}>
                                                <FormGroup className={'has-label'}>
                                                    <h6>open</h6>
                                                    Sending a text message with the command <i>"open"</i> to{' '}
                                                    {this.formatPhone(localStorage.getItem('smsPhoneNumber'))} will
                                                    allow people to join by texting 'Hi'.
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <Label
                                        style={{
                                            width: '100%',
                                            paddingTop: 40,
                                            paddingRight: 30,
                                            paddingBottom: 30,
                                            textAlign: 'right',
                                            display: this.state.privateLabelObject.id === null ? 'none' : '',
                                        }}
                                    >
                                        powered by{' '}
                                        <a href="https://replyallhealth.com" target="_blank" rel="noopener noreferrer">
                                            ReplyAll Health
                                        </a>
                                    </Label>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default DashboardForm;
